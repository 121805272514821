import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import "react-quill/dist/quill.snow.css";
import {FilledButton} from "../../buttons/buttons";

// actions
import {openNotification} from "../../../actions/notification";
import {closePopup} from "../../../actions/popup";
import {updateReading} from "../../../actions/data";
import {openPopup} from "../../../actions/popup";

import {removeProfile} from '../../../service/requests';

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => {
    return dispatch(closePopup());
  },
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  },
  updateReading: (payload) => {
    return dispatch(updateReading(payload));
  },
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  },
});

const RemoveUser = (props) => {
  const {closePopup, openNotification} = props;

  const remove = () => {
    removeProfile().then((res) => {
        closePopup()
        localStorage.clear();
        window.location.reload();
    }).catch(err => {
        openNotification({text: "Error", notificationType: "error"});
        closePopup();
    });
  }

  return (
    <div className="m-10">
      <div className="text-lg sm:text-xl font-bold text-center">Vuoi davvero eliminare il tuo profilo?</div>

      <div className="flex flex-row justify-center">
        <div className="flex justify-center mt-3 ml-5">
            <FilledButton id='cancelled_remove_user' text="No" onClick={closePopup} />
        </div>
        <div className="flex justify-center mt-3 ml-5">
            <FilledButton id='confirmed_remove_user' text="Si" onClick={remove} />
        </div>
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(RemoveUser);
