import React, {useEffect, useState} from "react";
import "./App.scss";
import {Routes, Route, useNavigate, Navigate} from "react-router-dom";
import {connect} from "react-redux";

// hooks
import {useWindowSize} from "./hooks/useWindowSize";

// compoennts
import Popup from "./components/popup/popup";
import Menu from "./components/menu/menu";
import MenuMobile from "./components/menu/menuMobile";
import Notification from "./components/notification/notification";

// pages
import Profile from "./pages/profile/profile";
import Home from "./pages/home/home";
import Dashboard from "./pages/dashboard/dashboard";

// actions
import {currentUser} from "./actions/currentUser";
import {openPopup, closePopup} from "./actions/popup";

// api
import {getUser, acceptPrivacy, declinePrivacy} from "./service/requests";

const mapStateToProps = (state) => ({
  ...state.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  currentUser: (payload) => {
    return dispatch(currentUser(payload));
  },
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  }
});

function App(props) {
  const {currentUser, user, openPopup } = props;
  const token = localStorage.getItem("token");
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const size = useWindowSize();
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const {width, height} = size;
    width <= 610 ? setIsMobile(true) : setIsMobile(false);
  }, [size]);


  const onGetUser = async () => {
    try {
      const res = await getUser();

      const user = res.data.user;

      if(user.role === 'nv') {
        return openPopup({currentPopup: "PrivacyConfirmation", info:{ onConfirm: () => { return acceptPrivacy() }, onCancel: () => { return declinePrivacy() } } })
      }

      currentUser({ user });
      setIsChecked(true);
    } catch (error) {
      console.log(error);
      setIsChecked(true);
    }
  };

  useEffect(() => {
    if (token) {
      onGetUser();
    } else {
      setIsChecked(true);
    }
  }, []);


  useEffect(() => {
    const url = new URL(window.location.href);
    const recoverCode = url.searchParams.get("recover");
    const recoverEmail = url.searchParams.get("email");

    if(recoverCode && recoverEmail) {
      openPopup({currentPopup: "Forgot"});
    }
  }, [])


  return (
    <div className="App">
          <Notification />
          <Popup />
      <div className={isMobile ? "flex flex-col" : "flex"}>
        <div>{isMobile ? <MenuMobile isChecked={isChecked}/> : <Menu isChecked={isChecked}  setIsChecked={setIsChecked} />}</div>
        <div className="px-3 sm:px-8 py-4 lg:px-16 lg:py-10 flex-1">

          {isChecked ? user ? (
            <Routes>
              <Route exact path="/" element={<Home />}></Route>
              <Route exact path="/profile" element={<Profile />}></Route>
              <Route exact path="/dashboard" element={<Dashboard />}></Route>
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
         ) : (
            <Routes>
              <Route exact path="/" element={<Home />}></Route>
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          ): <></>}

        </div>
      </div>
    </div>
  );
}

// export default App;

export default connect(mapStateToProps, mapDispatchToProps)(App);
