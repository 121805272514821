import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import moment from "moment";
import "./dashboard.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import parse from "html-react-parser";

import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// hooks
import {useWindowSize} from "../../hooks/useWindowSize";

// api
import {getAllReadings, deleteReadingReq} from "../../service/requests";

// actions
import {openPopup, closePopup} from "../../actions/popup";
import {openNotification} from "../../actions/notification";
import {setReadings, deleteReading} from "../../actions/data";

const mapStateToProps = (state) => ({
  ...state.data,
});

const mapDispatchToProps = (dispatch) => ({
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  },
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  },
  closePopup: () => {
    return dispatch(closePopup());
  },
  setReadings: (payload) => {
    return dispatch(setReadings(payload));
  },
  deleteReading: (payload) => {
    return dispatch(deleteReading(payload));
  },
});

const Dashboard = (props) => {
  const {openPopup, setReadings, readings, deleteReading, openNotification, closePopup} = props;

  const [userData, setUserData] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const size = useWindowSize();

  useEffect(() => {
    const {width, height} = size;
    width <= 610 ? setIsMobile(true) : setIsMobile(false);
  }, [size]);

  useEffect(() => {
    loadData();
    window.gtag('event', 'navigation', {
      eventLabel: 'I miei Consumi'
   });
  }, []);

  const loadData = async () => {
    try {
      const res = await getAllReadings();
      if (res.data.ok) {
        let readings = res.data.readings;

        readings.map(dt => {
          dt.date = dt.date.replace('Z', '');
        })

        readings.sort((a,b) => {
          // console.log(+new Date(a.date) > +new Date(b.date), a.date, b.date);
          if(+new Date(a.date) > +new Date(b.date)) {
            return 1
          } else if(+new Date(a.date) < +new Date(b.date)) {
            return -1
           } else {
              return 0
            } 
        }).map((reading, ind) => {
          const prevData = readings[ind - 1];

          if(!prevData) {
            // reading.consumssion = 0;
            reading["costo"] = 0
            reading["cons"] = 0
          } else {
            reading["costo"] = ((+reading.consumssion  - +prevData.consumssion) * 1.019).toFixed(2);
            reading["cons"] = (+reading.consumssion  - +prevData.consumssion).toFixed(2);
          }

          // reading["costo"] = (reading.consumssion * 2.467).toFixed(2);
        });
        setUserData(readings);
        setReadings({readings: readings});
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onEditData = (data) => {
    console.log("dataaaaa", data);

    let updatedReadings = userData;
    let updatedReading = userData.find((x) => x.id === data.id);
    let updatedReadingIndex = userData.indexOf(updatedReading);
    updatedReadings[updatedReadingIndex] = data;

    setUserData(updatedReadings);
  };

  const onDeleteData = async (data) => {
    console.log("data", data);

    try {
      const res = await deleteReadingReq(data.id);
      if (res.data && res.data.ok) {
        let updatedReadings = userData.filter((x) => x.id != data.id);
        closePopup();
        setUserData(updatedReadings);
        deleteReading({reading: data});
        openNotification({text: " Lettura eliminata", notificationType: "info"});
      }
    } catch (e) {
      console.log(e);
      openNotification({text: "Something went wrong", notificationType: "error"});
    }
  };

  // userData.sort((a,b) => {
  //   return +new Date(a.date) > +new Date(b.date) 
  // });

  return (
    <div className="fadeIn">
      <div className="title">I miei Consumi</div>
      <div className="mainContainer mainTableWrapper mt-2 my-20">
          <TableContainer className="mainTable">
            <Table aria-label="collapsible table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="mainTable__headerTitle">Data</div>
                  </TableCell>
                  <TableCell>
                    {isMobile 
                    ? <div className="mainTable__headerTitle">Consumo</div>
                    : <div className="mainTable__headerTitle">Consumo</div>
                  }
                  </TableCell>
                  <TableCell>
                    <div className="mainTable__headerTitle">Costo (solo gas)€</div>
                  </TableCell>

                  <TableCell>
                    {isMobile 
                    ? <div className="mainTable__headerTitle">Lettura</div>
                    : <div className="mainTable__headerTitle">Lettura</div>
                  }
                  </TableCell>

                  {!isMobile && (
                    <TableCell>
                      <div className="mainTable__headerTitle">Note</div>
                    </TableCell>
                  )}
                  <TableCell align="right">
                    <div className="mainTable__headerTitle">Modifica Lettura</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="usersTable">
                {userData.sort((a,b) => {
                  // console.log(+new Date(a.date) > +new Date(b.date), a.date, b.date);
                  if(+new Date(a.date) > +new Date(b.date)) {
                    return -1
                  } else if(+new Date(a.date) < +new Date(b.date)) {
                    return 1
                  } else {
                    return 0
                  }
                }).map((data, i) => (
                  <Row
                    key={data.id || +new Date()}
                    data={data}
                    index={i}
                    openPopup={openPopup}
                    // editData={() => editData(data)}
                    // deleteData={() => deleteData(data)}
                    onEditData={onEditData}
                    onDeleteData={onDeleteData}
                    isMobile={isMobile}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
      </div>
    </div>
  );
};

function Row(props) {
  const {data, index, openPopup, onEditData, onDeleteData, isMobile} = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        sx={{"& > * ": {borderBottom: "none"}}}
        className={index % 2 ? "oddRow" : "evenRow"}
        onClick={() => setOpen(!open)}
      >
        <TableCell>{moment(data.date).format("DD/MM/YYYY HH:mm:ss")}</TableCell>
        <TableCell>{(+data.cons)
                  .toLocaleString()
                  .replace(".", ",")
                  .replace(/\s/gim, ".")}</TableCell>
        <TableCell>{(+data.costo)
                  .toLocaleString()
                  .replace(".", ",")
                  .replace(/\s/gim, ".")}</TableCell>
        <TableCell>{(+data.consumssion)
                  .toLocaleString()
                  .replace(".", ",")
                  .replace(/\s/gim, ".")}</TableCell>
        {/* {!isMobile && <TableCell>{parse(data.note)}</TableCell>} */}
        {!isMobile && <TableCell>{data.note}</TableCell>}

        <TableCell style={{borderBottom: "none", marginLeft: 'auto'}} align="right" className="actionsButtons">
          {isMobile && (
            <Tooltip title="Show Note" placement="top">
              <IconButton aria-label="expand row" size="small" className="mr-1 sm:mr-3" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="Modifica" placement="top">
            <Button
              id='edit_record_dashboard'
              onClick={() => openPopup({currentPopup: "EditDashboard", info: {onEditData: onEditData, data: data}})}
              className="actionEditBtn"
              variant="fab"
              aria-label="Modifica"
            >
              <img src="./img/ico/editIcon.svg" />
            </Button>
          </Tooltip>

          <Tooltip title="Elimina" placement="top">
            <Button
              // onClick={() => deleteData(data)}
              id='delete_record_dashboard'
              onClick={() => openPopup({currentPopup: "Confirmation", info: {onConfirm: () => onDeleteData(data)}})}
              className="actionRemoveBtn"
              style={{marginRight: "10px"}}
              variant="fab"
              aria-label="Elimina"
            >
              <img src="./img/ico/deleteIcon.svg" />
            </Button>
          </Tooltip>
        </TableCell>
      </TableRow>

      {isMobile && (
        <TableRow className="expandedTableInfo">
          <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {/* <div className="py-3 px-5 w-full bg-white">{parse(data.note)}</div> */}
              <div className="py-3 px-5 w-full bg-white">{data.note}</div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
