import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonBase from "@mui/material/ButtonBase";
import Checkbox from "@mui/material/Checkbox";
import GoogleAuth from "../../googleAuth/googleAuth";


// api
import {createUser, createReading} from "../../../service/requests";

// icons
import IconButton from "@mui/material/IconButton";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";

// actions
import {openPopup, closePopup} from "../../../actions/popup";
import {currentUser} from "../../../actions/currentUser";
import { openNotification } from "../../../actions/notification";
import { setReadings } from "../../../actions/data";

const mapDispatchToProps = (dispatch) => ({
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  },
  closePopup: () => {
    return dispatch(closePopup());
  },
  currentUser: (payload) => {
    return dispatch(currentUser(payload));
  },

  setReadings: (payload) => {
    return dispatch(setReadings(payload))
  },
  openNotification: (payload) => {
    return dispatch(openNotification(payload))
  }
});

const Signup = (props) => {
  const {openPopup, currentUser, closePopup, setReadings, openNotification } = props;

  const clientId = "119326919458-efacc3jlri69hh9erltn4cae3q3kh8mc.apps.googleusercontent.com";
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isPrivacy, setIsPrivacy] = useState(false);

  const [user, setUser] = useState({
    name: "",
    last_name: "",
    email: "",
    password: "",
    newsletter: false,
  });

  const onUserChange = (e) => {
    setErrorMessage(null);
    setIsLoading(false);
    setUser({
      ...user,
      [e.target.id]: e.target.value,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const loadData = async (userData) => {
    let error = false;
    userData.forEach(async(x) => {
      try {
        const res = await createReading(x);
        console.log('res', res);
      } catch(e) {
        openNotification({text: 'Something went wrong', notificationType: 'error' });
        console.log(e);
        error = true
        return
      }

      if(!error) {
        localStorage.removeItem('userData');
      }
    })
  }


  const onSignUp = async (event) => {
    setErrorMessage("");
    event.preventDefault();

    setIsLoading(true);

    window.gtag('event', 'signup', {
      eventLabel: 'signup'
    });

    try {
      const res = await createUser(user);
      localStorage.setItem("token", res.data.token);
      console.log("user", res.data.user);
      currentUser({user: res.data.user});
      closePopup();
      setIsLoading(false);
      const userData = localStorage.getItem("userData");

      // upoload data from loaal storage is exaisted
      if(userData) {
        let updatedUserDate = JSON.parse([userData]);
        loadData(updatedUserDate);
        setReadings({readings: updatedUserDate});
      }

    } catch (error) {
        console.log(error);
        openNotification({text: 'Questa email è già registrata nei nostri sistemi', notificationType: 'error' });
        if (error && error.data) {
          // setErrorMessage(error.data.message);
        }
        setIsLoading(false);
      }
  };

  return (
    <div className="p-8 sm:py-10 sm:px-14">
      <div className="text-2xl sm:text-4xl font-bold text-blue text-center">Non sei ancora iscritto?</div>
      <div className="text-center mt-2 text-md sm:text-lg">Compila i campi</div>

      <form onSubmit={onSignUp}>
        <div className="flex flex-col sm:w-96 w-full mt-3 sm:mt-5 gap-3 relative">
          <TextField required id="name" label="Nome" variant="standard" onChange={(e) => onUserChange(e)} />
          <TextField required id="last_name" label="Cognome" variant="standard" onChange={(e) => onUserChange(e)} />
          <TextField
            required
            id="email"
            type="email"
            label="Indirizzo email"
            variant="standard"
            onChange={(e) => onUserChange(e)}
          />
          <TextField
            required
            id="password"
            label="Password"
            variant="standard"
            type={showPassword ? "text" : "password"}
            onChange={(e) => onUserChange(e)}
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(e) => handleMouseDownPassword(e)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffRoundedIcon /> : <VisibilityRoundedIcon />}
                </IconButton>
              ),
            }}
          />

          <FormControlLabel 
          className="mt-2" 
          control={<Checkbox required checked={isPrivacy} onChange={(e) => setIsPrivacy(!isPrivacy)} />} 
          label={<a className="cursor-pointer text-blue underline text-sm" href="https://selectra.net/note-legali" target="_blank" rel="noopener noreferrer">Informativa privacy*</a>} />

          <FormControlLabel 
          className="-mt-5" 
          control={<Checkbox checked={user.newsletter} onChange={(e) => setUser({...user, newsletter: !user.newsletter})} />} 
          label={<div className="cursor-pointer text-sm leading-4">Voglio ricevere la Newsletter con offerte esclusive e consigli utili per risparmiare sulle utenze di casa</div>} />

          {errorMessage && <div className="absolute top-0 right-0 text-xs text-red">{errorMessage}</div>}
        
        </div>

        <div className="mt-2">
          <div className={errorMessage ? "auth__login--error" : ""}>
            <ButtonBase 
              type="submit"
              id='register_user'
              disabled={!isPrivacy}
              className={(isLoading || !isPrivacy) ? "w-full mt-5 bg-gray text-white text-lg pointer-events-none rounded-lg text-center py-2 px-10" 
              : "w-full mt-5 bg-blue text-white text-lg cursor-pointer hover:bg-lightBlue transition rounded-lg text-center py-2 px-10"}>
                Iscriviti
            </ButtonBase>
          </div>
        </div>
      </form>

      <div className="text-gray mt-2 text-center">O</div>

      <div className="mt-3">
        <GoogleAuth privacy={isPrivacy}/>
      </div>

      <div className="text-sm text-blue dark:text-whitish text-center mt-8">
        Hai già un account?
        <span
          id='login_user'
          className="font-semibold text-purple ml-2 cursor-pointer transition hover:text-blue hover:underline"
          onClick={() => openPopup({currentPopup: "Login"})}
        >
          Entra
        </span>
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(Signup);
