import {
   OPEN_NOTIFICATION,
   CLOSE_NOTIFICATION
} from '../actions';

const reducer = ( state = {
   showNotification: false,
   notificationType: 'success',
   text: '',
}, action) => {

   const {
      notificationType,
      text
   } = action;

   console.log(action, 'actions');

   switch (action.type) {
      case OPEN_NOTIFICATION:
         {
         return {
            ...state,
            showNotification: true,
            notificationType: notificationType,
            text: text
         }
      }

      case CLOSE_NOTIFICATION: {
         return {
            ...state,
            showNotification: false
         }
      }

      default:
            return state;
   }
}

export default reducer;