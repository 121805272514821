import {
    SET_READINGS,
    ADD_READING,
    UPDATE_READING,
    DELETE_READING
} from './index.js';


export const setReadings = (payload) => {
    const { readings } = payload;
    return {
        type: SET_READINGS,
        readings
    }
 }
 
 export const addReading = (payload) => {
    const { reading } = payload;
    return {
        type: ADD_READING,
        reading
    }
 }
 
 export const updateReading = (payload) => {
    const { reading } = payload;
    return {
        type: UPDATE_READING,
        reading
    }
 }
 
 export const deleteReading = (payload) => {
    const { reading } = payload;
    return {
        type: DELETE_READING,
        reading
    }
 }