import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonBase from "@mui/material/ButtonBase";
import Checkbox from "@mui/material/Checkbox";
import GoogleAuth from "../../googleAuth/googleAuth";
import Button from "@mui/material/Button";


// api
import { forgetPass, checkRecoverCode, setNewPass } from "../../../service/requests";

// icons
import IconButton from "@mui/material/IconButton";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";

// actions
import {openPopup, closePopup} from "../../../actions/popup";
import {currentUser} from "../../../actions/currentUser";
import { openNotification } from "../../../actions/notification";
import { setReadings } from "../../../actions/data";

const mapDispatchToProps = (dispatch) => ({
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  },
  closePopup: () => {
    return dispatch(closePopup());
  },
  currentUser: (payload) => {
    return dispatch(currentUser(payload));
  },

  setReadings: (payload) => {
    return dispatch(setReadings(payload))
  },
  openNotification: (payload) => {
    return dispatch(openNotification(payload))
  }
});

const Forgot = (props) => {
  const {openPopup, currentUser, closePopup, setReadings, openNotification} = props;

  const [showEmail, setShowEmail] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showPasswordField, setShowPasswordField] = useState(false);

  const [user, setUser] = useState({
    email: "",
    password: "",
    repeat_passpwrd: "",
    recover_code: ""
  });

  useEffect(() => {
    const url = new URL(window.location.href);
    const recoverCode = url.searchParams.get("recover");
    const recoverEmail = url.searchParams.get("email");

    if(recoverCode && recoverEmail) {
        setUser({
            ...user,
            'email': recoverEmail,
            'recover_code': recoverCode
        });

        setShowEmail(false);
        setShowCode(false);
        setShowPassword(true);

        checkRecoverCode({ code: recoverCode, email: recoverEmail }).then(resp => {

        }).catch(() => {
            openNotification({text: 'The recover link not valud anymore!', notificationType: 'error' });

            setErrorMessage('The recover link not valud anymore!');
        });
    }
  }, []);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onUserChange = (e) => {
    setErrorMessage(null);
    setIsLoading(false);
    setUser({
      ...user,
      [e.target.id]: e.target.value,
    });
  };


  const onForgot = async (event) => {
    setErrorMessage("");
    event.preventDefault();

    setIsLoading(true);

    window.gtag('event', 'on_forget', {
      eventLabel: 'on_forget'
    });

    try {
        const res = await forgetPass({ email: user.email });

        setShowEmail(false);
        setShowCode(true);
        setShowPassword(false);
      } catch(e) {
        openNotification({text: 'Questa email non è registrata nei nostri sistemi', notificationType: 'error' });
        console.log(e);
        return
      }
  };

  const onCheckCode = async (event) => {
    setErrorMessage("");
    event.preventDefault();

    setIsLoading(true);

    window.gtag('event', 'on_check_code', {
      eventLabel: 'on_check_code'
    });

    try {
        const res = await checkRecoverCode({ code: user.recover_code, email: user.email });
        
        setShowEmail(false);
        setShowCode(false);
        setShowPassword(true);
    } catch(e) {
        openNotification({text: 'Something went wrong', notificationType: 'error' });
        console.log(e);
        return
    }
  };

  const onSetNewPassword = async (event) => {
    setErrorMessage("");
    event.preventDefault();

    setIsLoading(true);

    window.gtag('event', 'on_set_pass', {
      eventLabel: 'on_set_pass'
    });

    try {
        const res = await setNewPass({ code: user.recover_code, email: user.email, password: user.password });

        if(window.location.href.split('?').length > 1) {
            window.location.href = window.location.href.split('?')[0];
        }else {
            closePopup();
        }
    } catch(e) {
        openNotification({text: 'Something went wrong', notificationType: 'error' });
        console.log(e);
        return
    }
  };

  return (
    <div className="p-8 sm:py-10 sm:px-14">

        {
            showEmail && <>
                <div className="text-2xl sm:text-4xl font-bold text-blue text-center">Password dimenticata</div>
                <div className="text-center mt-2 text-md sm:text-lg">Inserisci la tua email e ti mandiamo le istruzioni per reimpostare la password</div>

                <form onSubmit={onForgot}>
                    <div className="flex flex-col sm:w-96 w-full mt-3 sm:mt-5 gap-3 relative">
                    <TextField
                        required
                        id="email"
                        type="email"
                        label="Indirizzo email"
                        variant="standard"
                        onChange={(e) => onUserChange(e)}
                    />

                    {errorMessage && <div className="absolute top-0 right-0 text-xs text-red">{errorMessage}</div>}
                    </div>

                    <div className="flex justify-center mt-5">
                    <Button
                        type="submit"
                        className= {!/^\S+@\S+\.\S+$/.test(user.email) 
                            ? "w-full capitalize mt-7 bg-gray text-white text-lg transition rounded-lg text-center py-2 px-10 pointer-events-none"
                            : "w-full capitalize mt-7 bg-blue text-white text-lg cursor-pointer hover:bg-lightBlue transition rounded-lg text-center py-2 px-10"
                          }
                        >
                        invia
                    </Button>
                </div>
                </form>
            </>
        }

        {
            showCode && <>
                <div className="text-2xl sm:text-4xl font-bold text-blue text-center">Inserire il codice di verifica</div>

                <form onSubmit={onCheckCode}>
                    <div className="flex flex-col sm:w-96 w-full mt-3 sm:mt-5 gap-3 relative">
                    <TextField
                        required
                        id="recover_code"
                        type="text"
                        label="codice di verifica"
                        variant="standard"
                        onChange={(e) => onUserChange(e)}
                    />

                    {errorMessage && <div className="absolute top-0 right-0 text-xs text-red">{errorMessage}</div>}
                    
                    </div>

                    <div className="flex justify-center mt-5">
                    <Button
                        type="submit"
                        className= {user.recover_code.length !== 6 
                            ? "w-full capitalize mt-7 bg-gray text-white text-lg transition rounded-lg text-center py-2 px-10 pointer-events-none"
                            : "w-full capitalize mt-7 bg-blue text-white text-lg cursor-pointer hover:bg-lightBlue transition rounded-lg text-center py-2 px-10"
                          }
                        >
                        Check
                    </Button>
                </div>
                </form>
            </>
        }

        {
            showPassword && <>
                <div className="text-2xl sm:text-4xl font-bold text-blue text-center">Inserisci la nuova password</div>

                <form onSubmit={onSetNewPassword}>
                    <div className="flex flex-col sm:w-96 w-full mt-3 sm:mt-5 gap-3 relative">
                   
                    {!errorMessage && 
                        <>
                        <TextField
                        required
                        id="password"
                        label="Nuova password"
                        variant="standard"
                        type={showPasswordField ? "text" : "password"}
                        onChange={(e) => onUserChange(e)}
                        InputProps={{
                        endAdornment: (
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPasswordField(!showPasswordField)}
                            onMouseDown={(e) => handleMouseDownPassword(e)}
                            edge="end"
                            >
                            {showPassword ? <VisibilityOffRoundedIcon /> : <VisibilityRoundedIcon />}
                            </IconButton>
                        ),
                        }}
                    />

                        <TextField
                            required
                            id="repeat_password"
                            label="Ripetere la nuova password"
                            variant="standard"
                            type={showPasswordField ? "text" : "password"}
                            onChange={(e) => onUserChange(e)}
                            InputProps={{
                            endAdornment: (
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPasswordField(!showPasswordField)}
                                onMouseDown={(e) => handleMouseDownPassword(e)}
                                edge="end"
                                >
                                {showPassword ? <VisibilityOffRoundedIcon /> : <VisibilityRoundedIcon />}
                                </IconButton>
                            ),
                            }}
                        />
                        </>
                    }

                    {errorMessage && <div className="text-center top-0 right-0 text-md text-red">{errorMessage}</div>}
                    
                    </div>

                    {!errorMessage &&
                        <div className="flex justify-center mt-5">
                            <Button
                                type="submit"
                                className= {user.password.length < 6 || (user.password !== user.repeat_password)
                                    ? "w-full capitalize mt-7 bg-gray text-white text-lg transition rounded-lg text-center py-2 px-10 pointer-events-none"
                                    : "w-full capitalize mt-7 bg-blue text-white text-lg cursor-pointer hover:bg-lightBlue transition rounded-lg text-center py-2 px-10"
                                }
                                >
                                Invia
                            </Button>
                        </div>
                    }

                    {errorMessage && 
                        <div className="flex justify-center mt-5">
                        <Button
                            onClick={closePopup}
                            className='"w-full capitalize mt-7 bg-blue text-white text-lg cursor-pointer hover:bg-lightBlue transition rounded-lg text-center py-2 px-10"'
                            >
                            Close
                        </Button>
                    </div>
                    }
                </form>
            </>
        }
    </div>
  );
};

export default connect(null, mapDispatchToProps)(Forgot);
