import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FilledButton, EmptyButton} from "../../../components/buttons/buttons";

import {closePopup} from "../../../actions/popup";
import {openNotification} from "../../../actions/notification";

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => {
    return dispatch(closePopup());
  },
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  },
});

const Confirmation = (props) => {
  const {closePopup, info} = props;
  const [isLoading, setIsLoading] = useState(false);

  const onConfirm = () => {
    setIsLoading(true);
    info.onConfirm();
  };

  return (
    <section className="my-5 mx-10 sm:my-10 sm:mx-20 relative">
      <img src="../img/ico/trashIcon.svg" className="m-auto" />
      <div className="mt-3 text-dark font-medium text-center text-lg sm:text-2xl">
        Vuoi davvero cancellare la lettura?
      </div>
      <div className="mt-5 sm:mt-10 flex-col sm:flex-row justify-center flex gap-5 flex-wrap">
        <EmptyButton id='cancelled_action' text="Annulla" onClick={() => closePopup()} />
        <FilledButton id='confirmed_action' text={info.yesBtnText ? info.yesBtnText : "Si, cancella"} onClick={() => onConfirm()} />
      </div>
    </section>
  );
};

export default connect(null, mapDispatchToProps)(Confirmation);
