import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import "./menu.scss";
import GoogleLogin from "react-google-login";
import CircularProgress from "@mui/material/CircularProgress";
import {NavLink, useNavigate, Link} from "react-router-dom";
import {useWindowSize} from "../../hooks/useWindowSize";
import Tooltip from "@mui/material/Tooltip";
import {gapi} from "gapi-script";
import GoogleAuth from "../googleAuth/googleAuth";

// api
import {loginGoogle, logout} from "../../service/requests";

// actions
import {openPopup} from "../../actions/popup";
import {openNotification} from "../../actions/notification";
import {currentUser} from "../../actions/currentUser";
import {setReadings} from "../../actions/data";


const mapStateToProps = (state) => ({
  ...state.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  },
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  },
  currentUser: (payload) => {
    return dispatch(currentUser(payload));
  },
  setReadings: (payload) => {
    return dispatch(setReadings(payload));
  },
});

const Menu = (props) => {
  const {openPopup, user, currentUser, setReadings, isChecked, setIsChecked} = props;
  const navigate = useNavigate();
  const [isTablet, setIsTablet] = useState(false);
  const size = useWindowSize();

  const {
    id,
    name,
    last_name,
    avatar,
  } = user || {};


  const showUserInfo = () => {
    return (
      <div className="mt-16 flex flex-col items-center px-2 w-full">
        <div className="w-14 h-14 bg-white rounded-full relative overflow-hidden">
          { avatar && <div className="">
            <img src={avatar} referrerpolicy="no-referrer"/>
          </div> }
          { !avatar && <div className="absoluteCentered uppercase text-blue">
            {(name.charAt(0).toUpperCase() + last_name.charAt(0).toUpperCase())}
          </div>}
        </div>
        <div className=" mt-2.5 text-white text-center w-full text-xs md:text-sm">
          <div className="">{name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()}</div>
          <div className="">{last_name.charAt(0).toUpperCase() + last_name.slice(1).toLowerCase()}</div>
        </div>
        {/* <div className="w-full md:w-min text-xs md:text-sm text-center text-white rounded-full py-1 px-2 md:px-4 bg-blue text-sm mt-3">
          #{id}
        </div> */}
      </div>
    );
  };

  const showLoginInfo = () => {
    return (
      <div className="menu__loginButtons">
        <div onClick={() => openPopup({currentPopup: "Login"})} className="flex justify-center border border-transparentWhite p-2 pl-10 pr-10 rounded-md gap-0 md:gap-1.5 text-sm text-pastelBlue w-full menu__loginBtn cursor-pointer hover:border-white hover:text-white transition">
          <div>Entra</div>
          <div>O</div>
          <div>Iscriviti</div>
        </div>
        {/* <GoogleAuth isMenu={true} isTablet={isTablet} /> */}
      </div>
    );
  };

  useEffect(() => {
    const {width, height} = size;
    width <= 820 ? setIsTablet(true) : setIsTablet(false);
  }, [size]);


  const logOut = () => {
    logout();
    currentUser({user: null});
    setReadings({readings: []});
    navigate("/");
  };


  return (
    <div className="menuWrapper">
      <div className="menu">
        <div className="mt-14">
          {isTablet ? <img className="w-20" src="./img/logo--mini.svg" alt="logo" /> : <img className="w-32" src="./img/gs-logo.png" alt="logo" />}
        </div>

        {isChecked ? (
          user ? (
            showUserInfo()
          ) : (
            showLoginInfo()
          )
        ) : (
          <div className="h-36 flex items-center justify-center mt-16">
            <CircularProgress style={{color: "white"}} />
          </div>
        )}

        {/* { showLoginInfo()} */}

        <div className="menu__items">
          <NavLink to="/" className="menu__item" id='home_link'>
            <object type="image/svg+xml" data="./img/ico/homeIcon.svg" className="w-6"></object>
            <p className="ml-5">Home</p>
          </NavLink>
          {user ? (
            <NavLink to="/dashboard" className="menu__item" id='dashboard_link'>
              <object type="image/svg+xml" data="./img/ico/dashboardIcon.svg" className="w-6"></object>
              <p className="ml-5">I miei Consumi</p>
            </NavLink>
          ) : (
            <Tooltip title="Entra qui per vedere i tuoi consumi" placement="bottom">
              <div className="menu__item menu__item--disabled">
                <object type="image/svg+xml" data="./img/ico/dashboardIcon.svg" className="w-6"></object>
                <p className="ml-5">I miei Consumi</p>
              </div>
            </Tooltip>
          )}

          {user ? (
            <NavLink to="/profile" className="menu__item" id='profile_link'>
              <object type="image/svg+xml" data="./img/ico/profileIcon.svg" className="w-6"></object>
              <p className="ml-5">Il mio Profilo</p>
            </NavLink>
          ) : (
            <Tooltip title="Entra qui per vedere i tuoi profilo" placement="bottom">
              <div className="menu__item menu__item--disabled">
                <object type="image/svg+xml" data="./img/ico/profileIcon.svg" className="w-6"></object>
                <p className="ml-5">Il mio Profilo</p>
              </div>
            </Tooltip>
          )}

          <a href='https://selectra.typeform.com/to/Ew2WLcUv' id='typeform_link' target="_blank" className="menu__item">
              <object type="image/svg+xml" data="./img/ico/feedback5.svg" className="w-6"></object>
              <p className="ml-5">Info e segnalazioni</p>
          </a>

          <div className="menu__item_double">
            <a href='https://selectra.net/' id='selectra_site_link' target="_blank" className="menu__item_double_button">
                {/* <object type="image/svg+xml" data="./img/ico/feedback5.svg" className="w-6"></object> */}
                <p className="ml-5 no-wrap">Chi siamo</p>
            </a>

            <a href='https://selectra.net/note-legali' target="_blank" className="menu__item_double_button">
                {/* <object type="image/svg+xml" data="./img/ico/feedback5.svg" className="w-6"></object> */}
                <p className="ml-5 no-wrap">Privacy</p>
            </a>
          </div>

          {user && (
            <div className="menu__logoutBtn mt-auto" onClick={logOut}>
              <object type="image/svg+xml" data="./img/ico/logoutIcon.svg" className="w-6"></object>
              <p className="ml-5">Esci dal profilo</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
