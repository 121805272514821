import React, {useState, useEffect} from "react";
import "./changePassword.scss";
import {connect} from "react-redux";
import {closePopup} from "../../../actions/popup";
import {openNotification} from "../../../actions/notification";
import {FilledButton, NextStepButton} from "../../../components/buttons/buttons";
import IconButton from "@mui/material/IconButton";
import { useWindowSize } from "../../../hooks/useWindowSize";
import TextField from "@mui/material/TextField";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";

// api
import { changePassword, confirmPassword } from '../../../service/requests';

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => {
    return dispatch(closePopup());
  },
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  },
});

const ChangePassword = (props) => {
  const {closePopup, openNotification} = props;
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);

  const [isNewPassShow, setIsNewPassShow] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
  const [passwordError, setPasswordError] = useState({isError: false, errorText: ""});
  const [showPassword, setShowPassword] = useState(false);


  useEffect(() => {
    const {width, height} = size;
    width <= 420 ? setIsMobile(true) : setIsMobile(false);
  }, [size]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validate = () => {
    if ((newPassword.length && newPasswordRepeat.length) && (newPassword === newPasswordRepeat)) {
      return true;
    } else {
      return false;
    }
  };

  const onPasswordChange = async () => {
    const isValid = validate();
    if(isValid) {
      try {
        const res = await changePassword({
          newPassword: newPassword,
          oldPassword: password
        });
        if(res.response && !res.response.data.ok){
          openNotification({text: 'Something went wrong', notificationType: 'error' });
          setIsNewPassShow(false);
          setPasswordError({isError: true, errorText: 'Password invalid' });
        }
        if(res.data.ok) {
          openNotification({text: 'Password was changed successfully', notificationType: 'success' });
          closePopup();
        }
      } catch(e) {
        console.log('e', e)
        const message = e?.data?.message  || 'Something went wrong!'
        openNotification({text: (message === 'Wrong password') ? 'La password inserita non è corretta' : message, notificationType: 'error' });
      }
    } else {
      setPasswordError({isError: true, errorText: 'Passwords do not match' });
    }
  };

  const onNextStep = () => {
    if (password.length > 0) {
      confirmPassword({ password }).then(resp => {
        setIsNewPassShow(true);
      }).catch(err => {
        setPasswordError({isError: true, errorText: "Password not valid!"});
      })
    } else {
      setPasswordError({isError: true, errorText: "Please ented your old password"});
    }
  };

  const onPrevStep = () => {
    if (password.length > 0) {
      setIsNewPassShow(false);
    } else {
      setPasswordError({isError: true, errorText: "Please ented your old password"});
    }
  };

  return (
    <section className="changePasswordWrapper">
      <div className="changePasswordContent">
    {/* <section className="my-2 mx-2 md:my-8 md:mx-16 changePasswordWrapper"> */}
      <img src={isNewPassShow ? "./img/ico/lockOpenIcon.svg" : "./img/ico/lockCloseIcon.svg"} className="m-auto" />
      <div className="mt-5 text-center text-dark dark:text-white font-light text-sm">Cambiare la password</div>

      <div className="mt-3 text-dark dark:text-white text-xl font-medium text-center">
        Inserisci la {isNewPassShow && "tua nuova"} password
      </div>

      {/* {passwordError.isError && (
        <div className="border absolute bottom-24 left-10">
          <div className="text--error w-9/12 mx-auto">{passwordError.errorText}</div>
        </div>
      )} */}

      {isNewPassShow ? (
        <>
          <div className="w-full sm:w-9/12 mx-auto mt-7">
            <TextField
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={newPassword}
              size={isMobile ? 'small' : 'medium'}
              fullWidth
              autoFocus={true}
              // error={passwordError.isError}
              placeholder="Inserire una nuova password"
              // helperText={passwordError.isError ? passwordError.errorText : ""}
              className="text-dark dark:text-white"
              onChange={(e) => {
                setNewPassword(e.target.value);
                setPasswordError({isError: false, errorText: ""});
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => handleMouseDownPassword(e)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOffRoundedIcon /> : <VisibilityRoundedIcon />}
                  </IconButton>
                ),
              }}
              label="Nuova password"
            />
          </div>
          <div className="w-full sm:w-9/12 mx-auto mt-4 sm:mt-7">
            <TextField
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={newPasswordRepeat}
              fullWidth
              size={isMobile ? 'small' : 'medium'}
              className="text-dark dark:text-white"
              error={passwordError.isError}
              helperText={passwordError.isError ? passwordError.errorText : ""}
              placeholder="Ripetere la nuova password"
              onChange={(e) => {
                setNewPasswordRepeat(e.target.value);
                setPasswordError({isError: false, errorText: ""});
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => handleMouseDownPassword(e)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOffRoundedIcon /> : <VisibilityRoundedIcon />}
                  </IconButton>
                ),
              }}
              label="Ripetere la nuova password"
            />
          </div>
        </>
      ) : (
        <div className="w-full sm:w-9/12 mx-auto mt-7 text-dark dark:text-white">
          <TextField
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            value={password}
            error={passwordError.isError}
            fullWidth
            size={isMobile ? 'small' : 'medium'}
            autoFocus={true}
            className="text-dark dark:text-white"
            helperText={passwordError.isError ? passwordError.errorText : ""}
            placeholder="Inserisci la tua password"
            onKeyDown={(e) => e.keyCode == 13 && onNextStep()}
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordError({isError: false, errorText: ""});
            }}
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(e) => handleMouseDownPassword(e)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffRoundedIcon /> : <VisibilityRoundedIcon />}
                </IconButton>
              ),
            }}
            label="Password"
          />
        </div>
      )}

      <div className="mx-auto w-full sm:w-max mt-5 sm:mt-10 flex gap-5">
        {isNewPassShow ? (
          <div className="mx-auto w-full sm:w-max flex gap-5">
            <FilledButton text="Precedente" id='prev_step_change_pass' onClick={onPrevStep} />
            <FilledButton text="Confermare" id='comfirm_change_pass' onClick={() => onPasswordChange()} />
          </div>
        ) : (
          <NextStepButton text="Prossimo" id='next_step_change_pass' onClick={onNextStep} />
        )}
      </div>
      </div>
    </section>
  );
};

export default connect(null, mapDispatchToProps)(ChangePassword);
