import React, {useState, useEffect} from "react";
import ButtonBase from "@mui/material/ButtonBase";
import CircularProgress from "@mui/material/CircularProgress";
import { useWindowSize } from "../../hooks/useWindowSize";
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';


export const FilledButton = (props) => {

  const {disabled, text, isLoading, onClick, type} = props;
  
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const {width, height} = size;
    width <= 420 ? setIsMobile(true) : setIsMobile(false);
  }, [size]);

  return (
    <ButtonBase 
    type={type} 
    disabled={disabled} 
    className={isMobile ? "roundedMaterial w-full border" : "roundedMaterial"
  }>
      <div
      onClick={onClick}
        className={
          isLoading
            ? "bg-lightGray px-10 py-2 rounded-lg text-lg text-white w-full whitespace-nowrap pointer-events-none"
            : "bg-deepBlue px-10 py-2 rounded-lg text-lg text-white w-full whitespace-nowrap hover:bg-lightBlue transition cursor-pointer"
        }
      >
        {isLoading && <CircularProgress size={14} color="#ffffff" className="mr-4" />}
        {text}
      </div>
    </ButtonBase>
  );
};

export const FilledButtonroundedFull = (props) => {
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const {width, height} = size;
    width <= 420 ? setIsMobile(true) : setIsMobile(false);
  }, [size]);
  return (
    <ButtonBase type={props.type} className={isMobile ? "roundedMaterial w-full border" : "roundedMaterial"}>
      <div
        onClick={props.onClick}
        className={
          props.isLoading
            ? "bg-lightGray px-10 py-2 rounded-full text-lg text-white w-full whitespace-nowrap pointer-events-none"
            : "bg-deepBlue px-10 py-2 rounded-full text-lg text-white w-full whitespace-nowrap hover:bg-lightBlue transition cursor-pointer"
        }
      >
        {" "}
        {props.isLoading && <CircularProgress size={14} color="#ffffff" className="mr-4" />}
        {props.text}
      </div>
    </ButtonBase>
  );
};

export const FilledButtonroundedFullBig = (props) => {
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const {width, height} = size;
    width <= 420 ? setIsMobile(true) : setIsMobile(false);
  }, [size]);
  return (
    <ButtonBase type={props.type} className={isMobile ? "roundedMaterial w-full border" : "roundedMaterial"}>
      <div
        onClick={props.onClick}
        className={
          props.isLoading
            ? "bg-lightGray px-12 shadow-md py-2 sm:py-2.5 rounded-full text-lg text-white w-full whitespace-nowrap pointer-events-none"
            : "bg-deepBlue px-12 shadow-md py-2 sm:py-2.5 rounded-full text-lg text-white w-full whitespace-nowrap hover:bg-lightBlue transition cursor-pointer"
        }
      >
        {" "}
        {props.isLoading && <CircularProgress size={14} color="#ffffff" className="mr-4" />}
        {props.text}
      </div>
    </ButtonBase>
  );
};


export const EmptyButton = (props) => {
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const {width, height} = size;
    width <= 420 ? setIsMobile(true) : setIsMobile(false);
  }, [size]);

  return (
    <ButtonBase type={props.type} className={isMobile ? "roundedMaterial w-full border" : "roundedMaterial"}>
      <div
        onClick={props.onClick}
        className="px-10 py-2 rounded-lg text-blue border border-blue font-normal w-full whitespace-nowrap hover:bg-pastelBlue transition cursor-pointer box-border"
      >
        {props.text}
      </div>
    </ButtonBase>
  );
};

export const DisableButton = (props) => {
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const {width, height} = size;
    width <= 420 ? setIsMobile(true) : setIsMobile(false);
  }, [size]);

  return (
    <div className={isMobile 
    ? "bg-gray w-full border text-center px-10 py-2 rounded-lg text-lg text-white whitespace-nowrap pointer-events-none" 
    : "bg-gray px-10 py-2 rounded-lg text-lg text-white w-min whitespace-nowrap pointer-events-none"}>

      {props.text}
    </div>
  );
};


export const NextStepButton = (props) => {
    
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const {width, height} = size;
    width <= 420 ? setIsMobile(true) : setIsMobile(false);
  }, [size]);

  return (
     <ButtonBase className={isMobile ? "roundedMaterial w-full border" : "roundedMaterial mx-auto"}>
        <div 
          onClick={props.onClick} 
          className="bg-deepBlue px-10 py-2 rounded-lg text-white w-full whitespace-nowrap hover:bg-lightBlue transition cursor-pointer">{props.text}<span className='ml-3'><ArrowForwardRoundedIcon fontSize='small'/></span></div>
     </ButtonBase>
  )
}