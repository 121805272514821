import React, { useEffect } from "react";
import { connect } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { closeNotification } from "../../actions/notification";

const mapStateToProps = (state) => ({
  ...state.notification,
});

const mapDispatchToProps = (dispatch) => ({
  closeNotification: () => {
    return dispatch(closeNotification());
  },
});

const Notification = (props) => {

  const { 
    closeNotification, 
    text, 
    notificationType, 
    showNotification 
  } = props;

  console.log('received props', props);

  // type of notifications :
  // error
  // warning
  // info
  // success

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    closeNotification();
  };

  const action = (
    <React.Fragment>
      <CloseRoundedIcon fontSize="small" />
    </React.Fragment>
  );

  return (
    <div style={{ zIndex: '100000'}}>
      <Snackbar
        open={showNotification}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} color={notificationType} variant="filled">
          {text}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
