import React, { useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from "moment";
import { display } from '@mui/system';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const options = {
    // spanGaps: true,
    scales: {
        x: {
            suggestedMin: 0,
            suggestedMax: 100,
          grid: {
            display: false,
            color: "rgba(255, 255, 0, 1)",
            ticks : {
                beginAtZero: true,
                suggestedMin: 0,
            }
          },
          ticks : {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: 100
        }
        },
        y: {
            suggestedMin: 0,
            minValue: 0,
          grid: {
            // display: false
            color: "rgba(0, 0, 0, 0.05)",
            ticks : {
                beginAtZero: true,
                suggestedMin: 0,
            },
          },
          ticks : {
            beginAtZero: true,
            suggestedMin: 0,
            }
        }
      },
      elements: {
        line: {
            fill: false,
            tension: 0
        }
    },
//   responsive: true,
    maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
      display: false
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
  },
};

const createLabels = (startDate, endDate, readings) => {
    const sDate = moment(startDate);
    const eDate = moment(endDate);
    let currDate = sDate;

    const labels = [];
    const labelsFull = [];

    while(currDate <= eDate) {
        const dateExist = readings.filter(read => moment(read.date).format('YYYY-MM-DD') == currDate.format('YYYY-MM-DD'));

        if(dateExist && dateExist[0]) {
            dateExist.map(ex => {
                labels.push(moment(ex.date).format('YYYY-MM-DD HH:mm:ss'))
                labelsFull.push(ex.date);
            });
            
        } else {
            labels.push(currDate.format('YYYY-MM-DD'));
            labelsFull.push(currDate.format());
        }

        currDate = currDate.add(1, 'days');
    }

    return [labels, labelsFull];
};


const HomeGraph = (props) => {
    const { 
        data: passedReadings, 
        startDate = moment().startOf('month'), 
        endDate = moment().endOf('month'), 
        showLines: {
            showReal = true, 
            showPredict = true, 
            showAvarage = true 
        },
        setStatistic
    } = props;

    const readings = passedReadings;

    const [labels, labelsFull] = createLabels(startDate, moment(endDate) < moment().endOf('month') ? moment().endOf('month') : endDate, readings);

    const filteredReadings = {};
    const filteredReadingsMidVal = {};

    readings.map(reading => {
        const { date, consumssion } = reading;

        const formatedDate = moment(date).format('YYYY-MM-DD hh:mm:ss');
        const formatedDateMidVal = moment(date).format('YYYY-MM-DD');

        if(filteredReadingsMidVal[formatedDateMidVal]) {
            filteredReadingsMidVal[formatedDateMidVal].consumssion = (+filteredReadingsMidVal[formatedDateMidVal].consumssion > +consumssion) ? +filteredReadingsMidVal[formatedDateMidVal].consumssion : +consumssion;
        } else {
            filteredReadingsMidVal[formatedDateMidVal] = { ...reading };
        }

        filteredReadings[formatedDate] = { ...reading };
    });

    const preparedReadings = [];
    const preparedReadingsMidVal = [];

    for(let key in filteredReadings) {
        preparedReadings.push(filteredReadings[key]);
    }

    for(let key in filteredReadingsMidVal) {
        preparedReadingsMidVal.push(filteredReadingsMidVal[key]);
    }

    // console.log(filteredReadingsMidVal, '');

    const lastWeek = {};
    let realData = [];
    const noData = [];
    const predictData = [];

    let avarageConssumsion = 0;
    let totalReal = 0;
    let totalPredict = 0;
    let prevValue = 0;
    let prevData = null;

    if(preparedReadingsMidVal && preparedReadingsMidVal[0] && preparedReadingsMidVal[preparedReadingsMidVal.length - 1]) {
        avarageConssumsion = +(+preparedReadingsMidVal[preparedReadingsMidVal.length - 1].consumssion - +preparedReadingsMidVal[0].consumssion);
    }

    let realDataExist = false;

    let countMissDays = 0;
    let currAddInd = 1;

    labels.map((label, index) => {
        const foundReading = preparedReadings.find(read => moment(read.date).format('YYYY-MM-DD HH:mm:ss') === label);
        const day = moment(label).isoWeekday();

        if(foundReading) {
            realDataExist = true;
            lastWeek[day] = { ...foundReading };

            if(countMissDays) {
                const middVal = (+foundReading.consumssion - +prevValue) / ++countMissDays;

                console.log(middVal, countMissDays, +foundReading.consumssion, +prevValue);

                realData.map(dt => {
                    if(!dt.consumssion && !dt.haveToBeNull) {
                        dt.consumssion += (+prevValue + (currAddInd * middVal));
                        currAddInd++;
                    }
                });
            }

            countMissDays = 0;
            currAddInd = 1;

            prevValue = foundReading.consumssion;
            prevData = foundReading;
            // totalReal += +foundReading.consumssion

            realData.push(foundReading);
            predictData.push(foundReading);
        } else {
            if(prevData) {
                countMissDays++;
            
                const data = { consumssion: null, haveToBeNull: false, date: labelsFull[index] };
                realData.push(data);
            } else {
                const data = { consumssion: null, haveToBeNull: true, date: labelsFull[index] };
                realData.push(data);
            }
                


            // const data = { consumssion: null };
            // realData.push(data);
            // if(!realDataExist) {
            //     const data = { consumssion: null };
            //     predictData.push(data);
            //     // realData.push({consumssion: null});
            // } else {
            //     const fakeData = lastWeek[day];

            //     if(fakeData) {
            //         prevValue = fakeData.consumssion;
            //         prevData = fakeData;

            //         totalPredict += +fakeData.consumssion;

            //         fakeData.date = label;
            //         predictData.push(fakeData);
            //         // realData.push({consumssion: null});
            //     } else {
            //         const cons = ((+middleValue + +prevValue) / 2).toFixed(2);

            //         totalPredict += +cons;

            //         const data = { consumssion: cons, date: label, note: null };
            //         predictData.push(data);
            //         // realData.push({consumssion: null});
            //     }
            // }
        }
    });

    let preparedData = [];
    let prevDataD = null;
    let lastCon = null;
    let lastInd = 0;

    for(let i = 0; i < realData.length; i++) {
        const currVal = realData[i];
        const date = realData[i]?.date

        if(!realData[i - 1] && !prevDataD) {
            // preparedData.push(0);
            continue
        } else {
            if(realData[i - 1].consumssion) {
                prevDataD = realData[i - 1];
            } else {
                // continue;
            }
        }

        if(!currVal.consumssion) {
            preparedData.push({ showData: null});
            continue;

        }

        if(!prevDataD)
            continue;

        const showData = +currVal.consumssion - +prevDataD.consumssion;

        totalReal += +showData;

        prevDataD = realData[i];

        preparedData.push({showData, date});
        lastCon = showData;
        lastInd = i;
    }

    if(preparedData.length < 2) {
        preparedData = [null]
    }

    const middleValue = (avarageConssumsion / (realData.filter(dt => dt.consumssion).length - 1)).toFixed(2);

    useEffect(() => {
        if(preparedReadings[0])
            setStatistic({ totalReal: (+totalReal).toFixed(2), totalPredict: (+totalPredict).toFixed(2), avarageConssumsion: preparedReadings.length > 1 ? (+middleValue).toFixed(2) : null });
    }, [passedReadings, startDate, endDate])

    preparedData[lastInd] = {showData: lastCon};


    preparedData = preparedData.filter(rd => {
        if(rd && moment(rd.date) >= moment(startDate) && moment(rd.date) <= moment(endDate)) {
            return true
        }

        return false;
    });

    console.log(preparedData, 'preparedData');

    const data = {
        labels: labels.filter(el => (new Date(el) > startDate && new Date(el) < endDate)).map(lb => moment(lb).format('DD-MM-YYYY HH')),
        datasets: [
        {
            fill: true,
            label: 'Consumo',
            data: preparedData?.map(rc => rc?.showData),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(13, 162, 235, 0.5)',
            lineTension: 0.5,
            hidden: !showReal,
            spanGaps: true,
            stepped: true,
        },
        // {
        //     label: 'Prediction',
        //     data: predictData.map(rl => rl.consumssion),
        //     borderColor: 'rgba(255, 99, 132, 0.5)',
        //     // backgroundColor: 'rgba(255, 99, 132, 0.5)',
        //     lineTension: 0.5,
        //     hidden: !showPredict,
        // },
        // {
        //     label: 'Avarage use',
        //     data: labels.map(e => middleValue),
        //     borderColor: 'rgba(146, 173, 203, 1)',
        //     // backgroundColor: 'rgba(255, 39, 132, 0.5)',
        //     lineTension: 0.5,
        //     hidden: !showAvarage,
        // },
          
          
        ],
      };

    return <Line options={options} width={100} height={100} data={data} />
}

export default HomeGraph;