import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import TextField from "@mui/material/TextField";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {MobileDatePicker} from "@mui/x-date-pickers/MobileDatePicker";
import {MobileDateTimePicker} from "@mui/x-date-pickers/MobileDateTimePicker";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {FilledButton, DisableButton} from "../../buttons/buttons";
import InputAdornment from "@mui/material/InputAdornment";
import EventIcon from "@mui/icons-material/Event";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import {it} from "date-fns/locale";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";

// api
import {updateReadingReq} from "../../../service/requests";

// actions
import {openNotification} from "../../../actions/notification";
import {closePopup} from "../../../actions/popup";
import {updateReading} from "../../../actions/data";

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => {
    return dispatch(closePopup());
  },
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  },
  updateReading: (payload) => {
    return dispatch(updateReading(payload));
  },
});

const EditDashboard = (props) => {
  const {closePopup, openNotification, info, updateReading} = props;

  const [data, setData] = useState(info.data);

  useEffect(() => {
    setData({...data, costo: (data.consumssion * 1.4).toFixed(2)});
  }, [data.consumssion]);

  const onEditData = async () => {
    try {
      data.date = moment(data.date).format("YYYY-MM-DD HH:mm:ss")
      const res = await updateReadingReq(data.id, data);

      if (res.data && res.data.ok) {
        info.onEditData(data);
        updateReading({reading: data});
        openNotification({text: "Data modificata", notificationType: "success"});
        closePopup();
      }
    } catch (e) {
      console.log(e);
      openNotification({text: "Something went wrong", notificationType: "error"});
    }
  };

  return (
    <div className="m-10">
      <div className="text-lg sm:text-xl font-bold text-center">Modifica data</div>

      <div className="mt-5 flex flex-col sm:flex-row justify-between gap-5">
        <div className="flex-1">
          <TextField
            className="w-full"
            label="Lettura"
            variant="outlined"
            type="number"
            id="lettura"
            value={data.consumssion}
            onChange={(e) => setData({...data, consumssion: e.target.value})}
          />
        </div>
        <div className="flex-1">
          <LocalizationProvider locale={it} dateAdapter={AdapterDateFns}>
            <MobileDateTimePicker
              label="Date"
              className="w-full"
              inputFormat="dd-MM-yyyy HH:mm:ss"
              value={data.date}
              disableFuture={true}
              onChange={(newValue) => setData({...data, date: new Date(newValue)})}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <EventIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </div>
      </div>

      {/* <div className="popup">
        <ReactQuill
          style={{marginTop: "18px"}}
          modules={Editor.modules}
          formats={Editor.formats}
          theme={"snow"}
          value={data.note}
          onChange={(text) => setData({...data, note: text})}
          placeholder="Note"
        />
      </div> */}

    <textarea rows="4" cols="50" 
      className="w-full p-3 rounded my-5 border border-gray" 
      placeholder="Note" value={data.note} 
      onChange={(e) => setData({...data, note: e.target.value})}/>

      <div className="flex justify-center mt-3">
        <FilledButton id='edited_data_dash' text="Salva" type="submit" onClick={onEditData} />
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(EditDashboard);

const Editor = {
  modules: {
    toolbar: [
      [{header: []}, {color: []}],
      ["bold", "italic", "underline", "blockquote"],
      [{list: "bullet"}, {indent: "-1"}, {indent: "+1"}],
      ["link"],
      ["clean"],
    ],
  },

  formats: [
    "header",
    "color",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ],
};
