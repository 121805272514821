import { combineReducers }  from 'redux';
import currentUser          from './currentUser';
import notification         from './notification';
import data                 from './data';
import popups               from './popup';


const rootReducer = combineReducers({
    notification,
    data,
    currentUser,
    popups,
});

export default (state, action) =>
  rootReducer(action.type === 'USER_LOGOUT' ? undefined : state, action);