import React, {useEffect} from "react";
import {connect} from "react-redux";
import {gapi} from "gapi-script";
import {useGoogleLogin} from "@react-oauth/google";

// api
import {loginGoogle, createReading, getAllReadings, getUser, acceptPrivacy, declinePrivacy} from "../../service/requests";

// actions
import {openPopup, closePopup} from "../../actions/popup";
import {currentUser} from "../../actions/currentUser";
import {openNotification} from "../../actions/notification";
import {setReadings} from "../../actions/data";

const mapDispatchToProps = (dispatch) => ({
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  },

  closePopup: () => {
    return dispatch(closePopup());
  },
  currentUser: (payload) => {
    return dispatch(currentUser(payload));
  },
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  },
  setReadings: (payload) => {
    return dispatch(setReadings(payload));
  },
});

const GoogleAuth = (props) => {
  const {openPopup, closePopup, currentUser, openNotification, isMenu, isTablet, privacy, disabled = false } = props;

  const clientId = "189062098162-o4n29brf3ughtvb493645uh3jeto9tve.apps.googleusercontent.com";

  useEffect(() => {
    gapi.load("client: auth2", () => {
      gapi.auth2.init({clientId: clientId});
    });
  }, []);

  // load all data

  const loadData = async (userData) => {
    let error = false;
    userData.forEach(async (x) => {
      try {
        const res = await createReading(x);
      } catch (e) {
        openNotification({text: "Something went wrong", notificationType: "error"});
        console.log(e);
        error = true;
        return;
      }

      if (!error) {
        localStorage.removeItem("userData");
      }
    });

    try {
      const res = await getAllReadings();
      if (res.data.ok) {
        let readings = res.data.readings;
        setReadings({readings: readings});
      }
    } catch (e) {
      openNotification({text: "Something went wrong", notificationType: "error"});
      console.log(e);
    }
  };

  const responseGoogle = async (token) => {
    const userData = localStorage.getItem("userData");
    window.gtag('event', 'login', {
      eventLabel: 'login - google'
    });

    if (token) {
      try {
        const res = await loginGoogle({ google_token: token, privacy });

        const {
          askPr,
          token: tempToken,
          user: { role }
        } = res.data;

        if(askPr || role === 'nv') {
          window.localStorage.setItem('token', tempToken);
          return openPopup({currentPopup: "PrivacyConfirmation", info:{ onConfirm: () => { return acceptPrivacy(token) }, onCancel: () => { return declinePrivacy() } } })
        }

        localStorage.setItem("token", res.data.token);

        try {
          const res = await getUser();
          currentUser({user: res.data.user});

          // upoload data from local storage is exaisted
          if (userData) {
            let updatedUserDate = JSON.parse([userData]);
            loadData(updatedUserDate);
          }
        } catch (error) {
          console.log(error);
          openNotification({text: "Something went wrong", notificationType: "error"});
          // setIsLoginBtnDisabled(false);
        }

        closePopup();
      } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
          openNotification({text: error?.response?.data.message, notificationType: "error"});
        }
      }
    }
  };

  const continueGoogle = async (token) => {
    const userData = localStorage.getItem("userData");
    window.gtag('event', 'login', {
      eventLabel: 'login - google'
    });

    if (token) {
      try {
        const res = await loginGoogle({ google_token: token, privacy: true });
        localStorage.setItem("token", res.data.token);

        try {
          const res = await getUser();
          currentUser({user: res.data.user});

          // upoload data from local storage is exaisted
          if (userData) {
            let updatedUserDate = JSON.parse([userData]);
            loadData(updatedUserDate);
          }
        } catch (error) {
          console.log(error);
          openNotification({text: "Something went wrong", notificationType: "error"});
          // setIsLoginBtnDisabled(false);
        }

        closePopup();
      } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
          openNotification({text: error?.response?.data.message, notificationType: "error"});
        }
      }
    }
  };

  const login = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (codeResponse) => {
      console.log("codeResponse", codeResponse);
      responseGoogle(codeResponse.code);
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  return (
    <>
      {isMenu ? (
        isTablet ? (
          <button className={disabled ? "menu__googleBtn menu__googleBtn--mobile pointer-events-none bg-gray" : "menu__googleBtn menu__googleBtn--mobile"} id='login_google' onClick={() => login()}>
            <img src="./img/ico/google.svg" />
          </button>
        ) : (
          <button disabled={disabled} className={disabled ? "menu__googleBtn" : "menu__googleBtn pointer-events-none bg-gray"} id='login_google' onClick={() => login()}>
            <img src="./img/ico/google.svg" />
            Continua con Google
          </button>
        )
      ) : (
        <button 
          id='login_google'
          className={disabled 
            ? "text-sm sm:text-base bg-lightGray pointer-events-none bg-gray text-white rounded-lg flex items-center justify-center w-full py-2.5 px-10 cursor-pointer hover:text-blue transition border border-transparent hover:border-transparentBlue" 
            : "text-sm sm:text-base bg-lightGray rounded-lg flex items-center justify-center w-full py-2.5 px-10 cursor-pointer hover:text-blue transition border border-transparent hover:border-transparentBlue"}
          onClick={() => login()}
        >
          <img className="w-5 mr-3" src="./img/ico/google.svg" />
          Continua con Google
        </button>
      )}
    </>
  );
};
export default connect(null, mapDispatchToProps)(GoogleAuth);
