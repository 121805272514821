// auth
export const CURRENT_USER = "CURRENT_USER";

// Notification
export const OPEN_NOTIFICATION = "OPEN_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";

// data
export const SET_READINGS = 'SET_READINGS';
export const ADD_READING = 'ADD_READING';
export const UPDATE_READING = 'UPDATE_READING';
export const DELETE_READING = 'DELETE_READING';

// Popup
export const OPEN_POPUP = "OPEN_POPUP";
export const CLOSE_POPUP = "CLOSE_POPUP";