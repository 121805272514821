import { SET_READINGS, ADD_READING, UPDATE_READING, DELETE_READING } from "../actions/index";

export default ( state = {
  readings: [],
}, action) => {

  const {
    readings,
    reading,
  } = action;

  switch (action.type) {
    case SET_READINGS: {
      return {
         ...state,
         readings: readings
      }
   }

    case ADD_READING: {
      return {
         ...state,
         readings: [...state.readings, reading]
      }

   }
   
   case UPDATE_READING: {
      let updatedReading = state.readings.find(x => x.id === reading.id);
      let updatedReadingIndex = state.readings.indexOf(updatedReading);
      let readings = state.readings;
      readings[updatedReadingIndex] = reading;

      return {
         ...state, 
         readings: [...readings]
      }
   }
   
   case DELETE_READING: {

      let deletedReading = state.readings.find(x => x.id === reading);
      return {
         ...state,
         readings: [...state.readings.filter(x => x !== deletedReading)]
      }
   }

      
    default:
      return state;
  }
};
