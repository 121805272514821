import React, { useEffect }            from 'react';
import { connect }      from 'react-redux';
import Dialog           from '@mui/material/Dialog';
import DialogContent    from '@mui/material/DialogContent';

import ChangePassword   from '../popups/changePassword/changePassword';
import Confirmation     from '../popups/confirmation/confirmation';
import PrivacyConfirmation     from '../popups/privacyConfirmation/privacyConfirmation';
import EditDashboard    from '../popups/editDashboard/editDashboard';
import Login            from '../popups/login/login';
import Signup           from '../popups/signup/signup';
import Forgot           from '../popups/forgot/forgot.js';
import askLogin         from '../popups/askLogin/askLogin';
import RemoveUser       from '../popups/removeUser/RemoveUser';


import {
   closePopup,
} from '../../actions/popup';

const mapStateToProps = state => ({
   ...state.popups
});

const mapDispatchToProps = dispatch => ({
   closePopup: () => {
      return dispatch(closePopup());
   },
})

const Popup = (props) => {

   const theme = localStorage.getItem("theme");
   
   const handleClose = () => {
      if(props.currentPopup === 'PrivacyConfirmation') 
         return;

      const url = new URL(window.location.href);
      const recoverCode = url.searchParams.get("recover");
      const recoverEmail = url.searchParams.get("email");

      if(recoverCode && recoverEmail) {
         window.location.href = window.location.href.split('?')[0];
      } else {
         props.closePopup();
      }
   }
   
   const map = {
      'ChangePassword': ChangePassword,
      'Confirmation':   Confirmation,
      'EditDashboard':  EditDashboard,
      'Login':          Login,
      'Signup':         Signup,
      "AskLogin":       askLogin,
      "RemoveUser":     RemoveUser,
      "Forgot":         Forgot,
      "PrivacyConfirmation": PrivacyConfirmation
   }

   window.gtag('event', 'open popup', {
      eventLabel: props.currentPopup
   });


   const CurrentPopup = map[props.currentPopup];
   
      return (
         <Dialog
            open={props.showPopup}
            onClose={handleClose}
            maxWidth="lg"
            className="dialog"
         >
            <DialogContent className="dialog--blurred dialog bg-whiteTransparent dark:bg-darkModeDark">
               {props.currentPopup !== 'PrivacyConfirmation' && <img src="./img/ico/closeIcon.svg" alt="close popup" onClick={handleClose} className='absolute top-6 right-6 cursor-pointer' />}
               {/* <span> test test test</span> */}
               { props.currentPopup && <CurrentPopup info={props.info} /> }
            </DialogContent>

         </Dialog>
      )
   
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(Popup);