import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FilledButton, EmptyButton} from "../../../components/buttons/buttons";

import {closePopup} from "../../../actions/popup";
import {openNotification} from "../../../actions/notification";

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => {
    return dispatch(closePopup());
  },
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  },
});

const PrivacyConfirmation = (props) => {
  const {closePopup, info} = props;
  const [isLoading, setIsLoading] = useState(false);

  const onConfirm = async () => {
    setIsLoading(true);
    const resp = await info.onConfirm();

    if(!resp?.data?.token) {
        window.location.reload();
    }

    const { token } = resp.data;

    window.localStorage.setItem('token', token);
    window.location.reload();

  };

  const onClose = () => {
    setIsLoading(true);
    info.onCancel();

    window.localStorage.removeItem('token');
    window.location.reload();
  }

  return (
    <section className="my-5 mx-10 sm:my-10 sm:mx-20 relative">
        <div className="text-2xl sm:text-4xl font-bold text-blue text-center">Accetta l’informativa privacy per concludere l’iscrizione</div>
      <div className="mt-3 text-dark font-medium text-center text-lg sm:text-2xl">
        Cliccando su “Sì, accetto”, confermi di aver letto e accettato la nostra informativa privacy.
      </div>
      <div className="mt-5 sm:mt-10 flex-col sm:flex-row justify-center flex gap-5 flex-wrap">
        <EmptyButton id='cancelled_action' text="Cancella" onClick={() => onClose()} />
        <FilledButton id='confirmed_action' text="Sì, accetto" onClick={() => onConfirm()} />
      </div>
    </section>
  );
};

export default connect(null, mapDispatchToProps)(PrivacyConfirmation);
