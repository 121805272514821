import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import "react-quill/dist/quill.snow.css";
import {FilledButton} from "../../buttons/buttons";

// actions
import {openNotification} from "../../../actions/notification";
import {closePopup} from "../../../actions/popup";
import {updateReading} from "../../../actions/data";
import {openPopup} from "../../../actions/popup";

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => {
    return dispatch(closePopup());
  },
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  },
  updateReading: (payload) => {
    return dispatch(updateReading(payload));
  },
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  },
});

const AskLogin = (props) => {
  const {closePopup, openNotification, info, updateReading, openPopup} = props;

  const onLogin = async () => {
    closePopup();
    setTimeout(() => {
        openPopup({currentPopup: "Login"})
    }, 50)
  };

  const onRegister = async () => {
    closePopup();
    setTimeout(() => {
        openPopup({currentPopup: "Signup"})
    }, 50)
  };

  return (
    <div className="m-10">
      <div className="text-lg sm:text-xl font-bold text-center">Non sei connesso. Registrati o fai il login per non perdere i tuoi consumi!</div>

      <div className="flex flex-row justify-center">
        <div className="flex justify-center mt-3 ml-5">
            <FilledButton text="Chiudi" type="submit" onClick={closePopup} />
        </div>
        <div className="flex justify-center mt-3 ml-5">
            <FilledButton text="Iscriviti" type="submit" id='register_user' onClick={onRegister} />
        </div>
        <div className="flex justify-center mt-3 ml-5">
            <FilledButton text="Connettiti" type="submit" id='login_user' onClick={onLogin} />
        </div>
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(AskLogin);
