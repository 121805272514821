import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import "./menuMobile.scss";
import GoogleLogin from "react-google-login";
import {NavLink} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

// api
import {getUser} from "../../service/requests";

// actions
import {currentUser} from "../../actions/currentUser";
import {openPopup} from "../../actions/popup";

const mapStateToProps = (state) => ({
  ...state.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  currentUser: (payload) => {
    return dispatch(currentUser(payload));
  },
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  },
});

const MenuMobile = (props) => {
  const {user, currentUser, openPopup, isChecked} = props;

  const {
    id,
    name,
    last_name,
    avatar,
  } = user || {};

  const showUserInfo = () => {
    return (
      <div className="flex items-center">
        <div className="flex flex-col text-sm mr-4 justify-center items-center">
          <div className="">
            {name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()}{" "}
            {last_name.charAt(0).toUpperCase() + last_name.slice(1).toLowerCase()}
          </div>
          {/* <div className="bg-blue px-2 rounded-full">#{id}</div> */}
          <div className="menu__item_double_mob">
            <a href='https://selectra.net/' target="_blank" className="menu__item_double_button">
                {/* <object type="image/svg+xml" data="./img/ico/feedback5.svg" className="w-6"></object> */}
                <p className="ml-5 no-wrap">Chi siamo</p>
            </a>

            <a href='https://selectra.net/note-legali' target="_blank" className="menu__item_double_button">
                {/* <object type="image/svg+xml" data="./img/ico/feedback5.svg" className="w-6"></object> */}
                <p className="ml-5 no-wrap">Privacy</p>
            </a>
          </div>
        </div>
        <div className="w-11 h-11 bg-white rounded-full relative overflow-hidden">
          { avatar && <div className="">
            <img src={avatar} referrerpolicy="no-referrer"/>
          </div> }

          { !avatar && <div className="absoluteCentered uppercase text-blue">
            {(name.charAt(0).toUpperCase() + last_name.charAt(0).toUpperCase())}
          </div>}
          
        </div>
      </div>
    );
  };

  const showLoginInfo = () => {
    return (
      <div onClick={() => openPopup({currentPopup: "Login"})} className="menu__loginBtn w-max text-sm px-5 flex justify-center border border-transparentWhite p-1 rounded cursor-pointer hover:border-white hover:text-white transition">
        <div>Entra o Iscriviti</div>
      </div>
    );
  };

  return (
    <>
      <div className="menuMobileHeader">
        <div className="menuMobile flex justify-between">
          <div className="w-20">
            <img src="./img/gs-logo.png" alt="logo" />
          </div>

         { isChecked && !user && <div className="menu__item_double_mob">
            <a href='https://selectra.net/' target="_blank" id='selectra_site_link' className="menu__item_double_button">
                {/* <object type="image/svg+xml" data="./img/ico/feedback5.svg" className="w-6"></object> */}
                <p className="ml-5 no-wrap">Chi siamo</p>
            </a>

            <a href='https://selectra.net/note-legali'  target="_blank" className="menu__item_double_button">
                {/* <object type="image/svg+xml" data="./img/ico/feedback5.svg" className="w-6"></object> */}
                <p className="ml-5 no-wrap">Privacy</p>
            </a>
          </div>}

          {isChecked ? (
            user ? (
              showUserInfo()
            ) : (
              showLoginInfo()
            )
          ) : (
            <div className="flex items-center justify-center">
              <CircularProgress style={{color: "white"}} />
            </div>
          )}

        </div>
      </div>

      <div className="menuMobile__items">
        <NavLink to="/" exact={true} className="menuMobile__item" id='home_link'>
          <object type="image/svg+xml" data="./img/ico/homeIcon.svg" className="w-4 mr-2"></object>
          <p>Home</p>
        </NavLink>

        {user ? (
          <NavLink to="/dashboard" exact={true} className="menuMobile__item" id='dashboard_link'>
            <object type="image/svg+xml" data="./img/ico/dashboardIcon.svg" className="w-4 mr-2"></object>
            <p>I miei Consumi</p>
          </NavLink>
        ) : (
          <div className="menuMobile__item menuMobile__item--disabled">
            <object type="image/svg+xml" data="./img/ico/dashboardIcon.svg" className="w-4 mr-2"></object>
            <p>I miei Consumi</p>
          </div>
        )}

        {user ? (
          <NavLink to="/profile" exact={true} className="menuMobile__item" id='profile_link'>
            <object type="image/svg+xml" data="./img/ico/profileIcon.svg" className="w-4 mr-2"></object>
            <p>Il mio Profilo</p>
          </NavLink>
        ) : (
          <div onClick={() => openPopup({currentPopup: "Login"})} className="border border-transparentWhite rounded p-2 w-max text-xs mb-3 self-center cursor-pointer hover:border-white hover:text-white transition">
            <div>Entra o Iscriviti</div>
          </div>
        )}

        <a href='https://selectra.typeform.com/to/Ew2WLcUv' id='typeform_link' target="_blank" className="fixed_feedback">
          <object type="image/svg+xml" data="./img/ico/feedback5.svg" className="w-6"></object>
        </a>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuMobile);
