import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import TextField from "@mui/material/TextField";
import {FilledButton, DisableButton} from "../../components/buttons/buttons";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {useNavigate} from "react-router-dom";
import Button from '@mui/material/Button';

// actions
import {openPopup} from "../../actions/popup";
import {openNotification} from "../../actions/notification";
import {currentUser} from "../../actions/currentUser";
import {setReadings} from "../../actions/data";

// api
import {updateUser, getUser, logout} from "../../service/requests";

// hooks
import {useWindowSize} from "../../hooks/useWindowSize";

const mapStateToProps = (state) => ({
  ...state.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  },
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  },
  currentUser: (payload) => {
    return dispatch(currentUser(payload));
  },
  setReadings: (payload) => {
    return dispatch(setReadings(payload));
  },
});

const Profile = (props) => {
  const {openPopup, user, currentUser, openNotification, setReadings} = props;
  const navigate = useNavigate();
  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  const [userInfo, setUserInfo] = useState(user);
  const [isLoading, setIsLoading] = useState(true);
  const [isActiveSaveUserInfoBtn, setIsActiveSaveUserInfoBtn] = useState(false);
  const [isActiveSaveUserDataBtn, setIsActiveSaveUserDataBtn] = useState(false);

  const [isCheckboxesChanged, setIsCheckboxesChanged] = useState(false);
  const [usingGas, setUsingGas] = useState({
    kitchen: userInfo.using_gas && userInfo.using_gas.kitchen ? userInfo.using_gas.kitchen : false,
    hWater: userInfo.using_gas && userInfo.using_gas.hWater ? userInfo.using_gas.hWater : false,
    heating: userInfo.using_gas && userInfo.using_gas.heating ? userInfo.using_gas.heating : false,
  })


  useEffect(() => {
    console.log('usingGas', usingGas)
  }, [usingGas])

  useEffect(() => {
    loadUserData();
    window.gtag('event', 'navigation', {
      eventLabel: 'Il mio Profilo'
   });
  }, []);

  const loadUserData = async () => {
    try {
      const res = await getUser();

      if (res.data.ok) {
        setIsLoading(false);
        setUserInfo(res.data.user);
        currentUser({user: res.data.user});
      }
    } catch (e) {
      console.log(e);
    }
  };


  useEffect(() => {
    if (user) {
      setUserInfo(user);
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    const {width, height} = size;
    width <= 610 ? setIsMobile(true) : setIsMobile(false);
  }, [size]);

  const onUserChange = (e) => {
    setIsActiveSaveUserInfoBtn(true);
    setUserInfo({
      ...userInfo,
      [e.target.id]: e.target.value,
    });
  };

  const onUserDataChange = (e) => {
    setIsActiveSaveUserDataBtn(true);
    setUserInfo({
      ...userInfo,
      [e.target.id]: e.target.value,
    });
  };

  const removeProfile = () => {
    props.openPopup({ currentPopup: "RemoveUser" })
  }

  const onCheckboxesChange = (item, value) => {
    setIsActiveSaveUserDataBtn(true);
    setIsCheckboxesChanged(true);
    setUsingGas({
      ...usingGas,
      [item]: !value,
    });
  }

  const onUpdateUserData = async (event, fromUserInfo) => {
    event.preventDefault();

    if (fromUserInfo) {
      setIsActiveSaveUserInfoBtn(false);
    } else {
      setIsActiveSaveUserDataBtn(false);
    }

    try {
      let updatedUserInfo = userInfo;

      if(isCheckboxesChanged) {
        updatedUserInfo = {...userInfo, using_gas: usingGas}
      }

      const res = await updateUser(updatedUserInfo);
      currentUser({user: updatedUserInfo});
      console.log("res", res);
      openNotification({text: "Data updated successfully", notificationType: "success"});
    } catch (e) {
      console.log(e);
      openNotification({text: "Something went wrong", notificationType: "error"});
    }
  };

  const logOut = () => {
    logout();
    currentUser({user: null});
    setReadings({readings: []});
    navigate("/");
  };

  return (
    <div className="fadeIn">
      <div className="title">Il mio Profilo</div>
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <>
          <div className="mainContainer w-full xl:w-8/12">
            <form onSubmit={(e) => onUpdateUserData(e, true)}>
              <div className="flex justifut-between flex-wrap gap-5">
                <div className="w-full md:flex-1">
                  <TextField
                    className="mainInput plain"
                    label="Nome"
                    size={isMobile ? "small" : "medium"}
                    variant="outlined"
                    type="text"
                    value={userInfo.name}
                    id="name"
                    onChange={(e) => onUserChange(e)}
                  />
                </div>

                <div className="w-full md:flex-1">
                  <TextField
                    className="mainInput plain"
                    label="Cognome"
                    variant="outlined"
                    size={isMobile ? "small" : "medium"}
                    type="text"
                    value={userInfo.last_name}
                    id="last_name"
                    onChange={(e) => onUserChange(e)}
                  />
                </div>
              </div>

              <div className="flex justifut-between mt-5 flex-wrap gap-5">
                <div className="w-full md:flex-1">
                  <TextField
                    className="mainInput plain"
                    label="Email"
                    variant="outlined"
                    size={isMobile ? "small" : "medium"}
                    type="text"
                    value={userInfo.email}
                    id="email"
                    onChange={(e) => onUserChange(e)}
                  />
                </div>

                {/* <div className="w-full md:flex-1 pointer-events-none opacity-0">
                  <TextField
                    className="mainInput plain"
                    label="Phone Number"
                    variant="outlined"
                    value={userInfo.phone}
                    size={isMobile ? "small" : "medium"}
                    type="text"
                    id="phone"
                    onChange={(e) => onUserChange(e)}
                  />
                </div> */}
              </div>

              <div className="flex justify-center mt-5">
                <FilledButton id='update_profile' text="Salva" type="submit" disabled={!isActiveSaveUserInfoBtn} />
              </div>
            </form>

            {/* CHANGE PASSWORD SECTION */}
            
            { !userInfo.google && <hr className="text-hr mt-7" /> }
            { !userInfo.google && <div
              className="flex justify-between pt-5 pb-0 sm:pt-6 sm:pb-4 cursor-pointer hover:text-blue transition"
              onClick={() => openPopup({currentPopup: "ChangePassword"})}
            >
              <div className="text-lg">Cambiare la password</div>
              <div>
                <img src="./img/ico/rightArrow.svg" alt="change password icon" />
              </div>
            </div>}
          </div>

          <form onSubmit={(e) => onUpdateUserData(e, false)}>
            <div className="w-full xl:w-8/12">
              {/* <div className="subtitle mt-7 pb-3">La mia situazione</div>
              <div className="mainContainer">
                <div className="flex flex-wrap gap-5">
                  <div className="w-full md:flex-1">
                    <TextField
                      className="mainInput plain"
                      label="Budget massimo giornaliero"
                      variant="outlined"
                      type="text"
                      value={userInfo.max_budget}
                      id="max_budget"
                      size={isMobile ? "small" : "medium"}
                      onChange={(e) => onUserDataChange(e)}
                    />
                  </div>
                  <div className="w-full md:flex-1">
                    <TextField
                      className="mainInput plain"
                      label="Costo da Contratto"
                      variant="outlined"
                      type="text"
                      size={isMobile ? "small" : "medium"}
                      value={userInfo.gas_cost}
                      id="gas_cost"
                      onChange={(e) => onUserDataChange(e)}
                    />
                  </div>
                </div>
              </div> */}
            </div>

            <div className="w-full xl:w-8/12 mb-20 sm:mb-0">
              {/* <div className="subtitle mt-7 pb-3">Altre Informazioni</div>
              <div className="mainContainer">
                <div className="flex flex-wrap gap-5">
                  <div className="w-full md:flex-1">
                    <TextField
                      className="mainInput plain"
                      size={isMobile ? "small" : "medium"}
                      label="Codice Passaparola"
                      variant="outlined"
                      type="text"
                      value={userInfo.word_user}
                      id="word_user"
                      onChange={(e) => onUserDataChange(e)}
                    />
                  </div>
                  <div className="w-full md:flex-1">
                    <TextField
                      className="mainInput plain"
                      label="Provincia di Residenza/Domicilio"
                      size={isMobile ? "small" : "medium"}
                      variant="outlined"
                      type="text"
                      value={userInfo.provice}
                      id="provice"
                      onChange={(e) => onUserDataChange(e)}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap gap-5 mt-5">
                  <div className="w-full md:flex-1">
                    <TextField
                      className="mainInput plain"
                      label="Numero Abitanti"
                      variant="outlined"
                      type="text"
                      size={isMobile ? "small" : "medium"}
                      value={userInfo.people}
                      id="people"
                      onChange={(e) => onUserDataChange(e)}
                    />
                  </div>
                  <div className="w-full md:flex-1">
                    <TextField
                      className="mainInput plain"
                      label="Superficie Casa (m^2)"
                      variant="outlined"
                      type="text"
                      size={isMobile ? "small" : "medium"}
                      value={userInfo.area}
                      id="area"
                      onChange={(e) => onUserDataChange(e)}
                    />
                  </div>
                </div>

                  <div className="mt-5 text-lg font-bold">Destinazione Gas</div>
                  <div className="flex flex-wrap gap-5 mt-5">
                    <FormControlLabel 
                      label="Cucina"
                      className={usingGas.kitchen ? "defaultCheckbox--checked" : "defaultCheckbox"} 
                      control={<Checkbox checked={usingGas.kitchen} onChange={() => onCheckboxesChange("kitchen", usingGas.kitchen)}/>} 
                    /> 

                    <FormControlLabel 
                      label="Acqua Calda" 
                      className={usingGas.hWater ? "defaultCheckbox--checked" : "defaultCheckbox"} 
                      control={<Checkbox checked={usingGas.hWater} onChange={() => onCheckboxesChange("hWater", usingGas.hWater)}/>} 
                    />

                    <FormControlLabel 
                      label="Riscaldamento" 
                      className={usingGas.heating ? "defaultCheckbox--checked" : "defaultCheckbox"} 
                      control={<Checkbox checked={usingGas.heating} onChange={() => onCheckboxesChange("heating", usingGas.heating)}/>} 
                      />
                  </div>
              </div> */}
              {/* <div className="flex justify-center mt-5">
                <FilledButton text="Save" type="submit" disabled={!isActiveSaveUserDataBtn} />
              </div> */}
              <div className="flex justify-center mt-5">
                <Button id='remove_profile' onClick={removeProfile} variant="contained" color="error">Elimina profilo</Button>
              </div>
            </div>
          </form>

          {isMobile && (
            <div
              className="mt-3 mb-20 flex bg-transparentRed border border-red text-red px-5 py-2 rounded-lg w-max"
              id='logout'
              onClick={logOut}
            >
              <img src="./img/ico/logoutIcon-red.svg" alt="logout icon" />
              <p className="ml-3">Log Out</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
