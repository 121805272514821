import React, {useEffect, useState, useRef} from "react";
import "./home.scss";
import {connect} from "react-redux";
import moment from "moment";
import TextField from "@mui/material/TextField";
import {FilledButtonroundedFullBig} from "../../components/buttons/buttons";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {MobileDatePicker} from "@mui/x-date-pickers/MobileDatePicker";
import {MobileDateTimePicker} from "@mui/x-date-pickers/MobileDateTimePicker";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import InputAdornment from "@mui/material/InputAdornment";
import EventIcon from "@mui/icons-material/Event";
import IconButton from "@mui/material/IconButton";
import HomeGraph from "../../components/graphs/homeGraph/homeGraph";
import {useWindowSize} from "../../hooks/useWindowSize";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import {it} from "date-fns/locale";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";

// api
import {createReading, getAllReadings} from "../../service/requests";

// actions
import {openNotification} from "../../actions/notification";
import {addReading} from "../../actions/data";
import {openPopup} from "../../actions/popup";

const mapStateToProps = (state) => ({
  ...state.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  },
  addReading: (payload) => {
    return dispatch(addReading(payload));
  },
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  },
});

const Home = (props) => {
  const {user, openNotification, addReading} = props;

  const size = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  const [loadAgain, setLoadAgain] = useState(false);
  const [showReal, setShowReal] = useState(true);
  const [showPredict, setShowPredict] = useState(true);
  const [showAvarage, setShowAvarage] = useState(false);
  const [statistic, setStatistic] = useState(null);
  const [startDate, setStartDate] = useState(moment().startOf("month"));
  const [endDate, setEndDate] = useState(moment().endOf("month"));

  const [consumssion, setConsumssion] = useState("");
  const [date, setDate] = useState(new Date());
  const [note, setNote] = useState("");

  const [readings, setReadings] = useState([]);
  const textFieldRef = useRef(null);

  useEffect(() => {
    const handleWheel = (e) => e.preventDefault();
    textFieldRef.current.addEventListener("wheel", handleWheel);

    return () => {
      if (textFieldRef.current) {
        textFieldRef.current.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  useEffect(() => {
    const {width, height} = size;
    width <= 420 ? setIsMobile(true) : setIsMobile(false);
  }, [size]);

  useEffect(() => {
    getAllReadings()
      .then((resp) => {
        const {readings} = resp.data;

        readings.map(dt => {
          dt.date = dt.date.replace('Z', '');
        })

        const first = readings.sort((a,b) => {
          // console.log(+new Date(a.date) > +new Date(b.date), a.date, b.date);
          if(+new Date(a.date) > +new Date(b.date)) {
            return 1
          } else if(+new Date(a.date) < +new Date(b.date)) {
            return -1
           } else {
              return 0
            } 
        });

        if(first && first[0]) {
          const firstDate = first[0].date;
          setStartDate(new Date(firstDate))
        }

        setReadings(readings);
      })
      .catch((error) => {
        const readings = JSON.parse(localStorage.getItem("userData"));

        setReadings(readings || []);

        console.log(error);
      });
  }, [loadAgain, user]);

  const onCalculate = async (event) => {
    window.gtag('event', 'click', {
      eventLabel: 'calculate - lettura',
      value: consumssion
    });

    event.preventDefault();
    const data = {consumssion: consumssion, date: moment(date).format("YYYY-MM-DD HH:mm:ss"), note: note};

    const errorAmount = (consumssion || consumssion === 0) ? consumssion < Math.max(...readings?.map(elem => +elem.consumssion) || 0): false;

    if(errorAmount) {
      openNotification({text: "la lettura è inferiore alla precedente", notificationType: "error"});
      return
    }

    if (!user) {
      let existingUserData = JSON.parse(localStorage.getItem("userData"));
      if (existingUserData == null) existingUserData = [];
      existingUserData.push(data);
      localStorage.setItem("userData", JSON.stringify(existingUserData));
      openNotification({text: "Lettura salvata", notificationType: "success"});

      const first = [...readings, data].sort((a,b) => {
        // console.log(+new Date(a.date) > +new Date(b.date), a.date, b.date);
        if(+new Date(a.date) > +new Date(b.date)) {
          return 1
        } else if(+new Date(a.date) < +new Date(b.date)) {
          return -1
         } else {
            return 0
          } 
      });

      if(first && first[0]) {
        const firstDate = first[0].date;
        setStartDate(new Date(firstDate))
      }

      setReadings([...readings, data]);
      props.openPopup({currentPopup: "AskLogin"});
    } else {
      try {
        const res = await createReading(data);
        
        const first = [...readings, data].sort((a,b) => {
          // console.log(+new Date(a.date) > +new Date(b.date), a.date, b.date);
          if(+new Date(a.date) > +new Date(b.date)) {
            return 1
          } else if(+new Date(a.date) < +new Date(b.date)) {
            return -1
           } else {
              return 0
            } 
        });
  
        if(first && first[0]) {
          const firstDate = first[0].date;
          setStartDate(new Date(firstDate))
        }

        addReading({reading: data});
        setReadings([...readings, data]);

        if (res.data && res.data.ok) {
          openNotification({text: "Lettura salvata", notificationType: "success"});
        }
      } catch (e) {
        console.log(e);
        openNotification({text: "Something went wrong", notificationType: "error"});
      }
    }

    // clear form
    setConsumssion("");
    setNote("");
    setDate(moment());
  };

  const errorAmount = (consumssion || consumssion === 0) ? consumssion < Math.max(...readings?.map(elem => +elem.consumssion) || 0): false;

  return (
    <div className="w-full fadeIn">
      {/* <div className="home__banner">
        <div className="flex blueGradient px-5 lg:px-10 rounded-2xl overflow-hidden justify-between items-center w-full relative">
          <div className="py-5 lg:py-10 w-7/12 sm:w-8/12 mr-4">
            <div className="text-white text-2xl font-bold">Calculate dolor sit amet</div>
            <div className="text-trasparentWhite text-sm sm:text-md mt-2 sm:mt-4">
              Calculate dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            </div>
          </div>

          {isMobile ? (
            <div className="absolute -right-3 top-4">
              <img src="./img/banner-mobile.svg" alt="banner image" />
            </div>
          ) : (
            <div className="self-end mt-6">
              <img src="./img/banner.svg" alt="banner image" />
            </div>
          )}
        </div>
      </div> */}

      <div className="mainContainer mt-2 sm:mt-5 mb-10 sm:mb-14">
        <div className="subtitle">Aggiungi lettura</div>

        <form onSubmit={onCalculate} className="home__userDataFrom">
          <div className="home__userDataInputs">
            <div className="flex flex-col justify-between sm:flex-row">
              <div className="home__input sm:mr-6">
                <TextField
                  className="mainInput"
                  required
                  type="number"
                  ref={textFieldRef}
                  label="Lettura"
                  variant="outlined"
                  value={consumssion}
                  onChange={(e) => setConsumssion(e.target.value)}
                  error={errorAmount}
                  helperText={errorAmount ? "Non è possibile inserire un consumo inferiore a l'ultimo consumo inserito" : ''}
                />
              </div>
              <div className="mainInput home__input mt-5 sm:mt-0">
                <LocalizationProvider locale={it} dateAdapter={AdapterDateFns}>
                  <MobileDateTimePicker
                    label="Data"
                    inputFormat="dd-MM-yyyy 'Ore' HH"
                    value={date}
                    size={isMobile ? "small" : "medium"}
                    disableFuture={true}
                    onChange={(date) => {
                      setDate(new Date(date));
                    }}
                    // formatDate={(date) => moment(new Date(date)).format('YYYY-MM-DD')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton edge="end">
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>

            {/* <div className="home__editorWrapper mb-5">
              <ReactQuill
                style={{marginTop: "18px"}}
                modules={Editor.modules}
                formats={Editor.formats}
                theme={"snow"}
                value={note}
                onChange={(text) => setNote(text)}
              />
              </div> */}

            <textarea
              rows="4"
              cols="50"
              className="w-full p-3 rounded-3xl my-5"
              placeholder="Note"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
          <FilledButtonroundedFullBig
            id='add_new_record_home'
            text={
              <span className="flex items-center justify-center">
                <AddRoundedIcon />
                <span className="ml-1">Inserisci</span>
              </span>
            }
            type="submit"
          />
        </form>
      </div>

      {/* <ul className="flex justify-center sm:justify-end mb-5 gap-4">
        <div onClick={() => setShowReal(!showReal)} className={showReal ? "home__chartBtn--active" : "home__chartBtn"}>
          Consumi reali
        </div>
        <div
          onClick={() => setShowPredict(!showPredict)}
          className={showPredict ? "home__chartBtn--active" : "home__chartBtn"}
        >
          Consumi predetti
        </div>
        <div
          onClick={() => setShowAvarage(!showAvarage)}
          className={showAvarage ? "home__chartBtn--active" : "home__chartBtn"}
        >
          Media Giornaliera
        </div>
      </ul> */}
      <div className="mainContainer p-5">
        <div className="title">Consumo giornaliero (mc/g)</div>
        <div className="flex flex-wrap justify-start max-w flex-col sm:flex-row mb-10">
            <div className="mainInput home__input mt-5 sm:mt-5 mr-5">
              <LocalizationProvider locale={it} dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Data Inizio"
                  inputFormat="dd-MM-yyyy"
                  value={startDate}
                  size={isMobile ? "small" : "medium"}
                  onChange={(date) => {
                    setStartDate(new Date(date));
                  }}
                  // formatDate={(date) => moment(new Date(date)).format('YYYY-MM-DD')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end">
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="mainInput home__input mt-5 sm:mt-5">
              <LocalizationProvider locale={it} dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Data Fine"
                  inputFormat="dd-MM-yyyy"
                  value={endDate}
                  size={isMobile ? "small" : "medium"}
                  onChange={(date) => {
                    setEndDate(new Date(date));
                  }}
                  // formatDate={(date) => moment(new Date(date)).format('YYYY-MM-DD')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end">
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
        <div className="chart_blok">
          <HomeGraph
            data={readings}
            showLines={{showReal, showPredict, showAvarage}}
            setStatistic={setStatistic}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
        {/* <img src="./img/ico/noDataIcon.svg" className="w-16" alt="no data" /> */}
      </div>
      <div className="text-gray text-sm mt-5 ml-5">I dati compaiono sul grafico una volta inserite almeno 2 letture</div>

      {
        <div className="mainContainer mt-10 sm:my-10">
          <div className="subtitle">I miei consumi</div>
          {statistic && (
            <div className="flex flex-col gap-3 sm:gap-5 my-2">
              <div className="flex items-start">
                <span className="actual_dot mr-2 mt-1"></span>
                <span className="font-bold whitespace-nowrap mr-2">
                  Totale Consumi: </span> &nbsp;
                  {parseFloat((statistic.totalReal * 1).toFixed(2))
                    .toLocaleString()
                    .replace(".", ",")
                    .replace(/\s/gim, ".")} mc  consumati in totale che corrispondono ad una spesa stimata di {" "}
                {parseFloat((statistic.totalReal * 1.019).toFixed(2))
                  .toLocaleString()
                  .replace(".", ",")
                  .replace(/\s/gim, ".")} €
              </div>
              {statistic.avarageConssumsion && (
                <div className="flex items-start">
                  <span className="avarage_dot mr-2 mt-1"></span>
                  <span className="font-bold whitespace-nowrap mr-2">Media Giornaliera: </span>&nbsp;
                  {parseFloat((statistic.avarageConssumsion * 1).toFixed(2))
                    .toLocaleString()
                    .replace(".", ",")
                    .replace(/\s/gim, ".")} mc/giorno consumati in media per una spesa giornaliera di {" "}
                  {parseFloat((statistic.avarageConssumsion * 1.019).toFixed(2))
                    .toLocaleString()
                    .replace(".", ",")
                    .replace(/\s/gim, ".")} €/giorno
                </div>
              )}
            </div>
          )}
        </div>
      }
      <div className="text-gray text-sm mt-5 ml-5">Il costo riportato si riferisce alla materia prima gas utilizzando una media del prezzo del PSV degli ultimi 3 mesi. Il Totale Consumi e la Media Giornaliera si riferiscono ai consumi inseriti nel periodo selezionato.</div>
      <div className="pb-20"></div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

const Editor = {
  modules: {
    toolbar: [
      [{header: []}, {color: []}],
      ["bold", "italic", "underline", "blockquote"],
      [{list: "bullet"}, {indent: "-1"}, {indent: "+1"}],
      ["link"],
      ["clean"],
    ],
  },

  formats: [
    "header",
    "color",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ],
};
