import axios from 'axios';

export const baseURL = (process.env.NODE_ENV === 'production') ? '/' : 'http://localhost:3231/';
const api = baseURL + 'api/v1/';
const usersAPI = api + 'users';
const readingsAPI = api + 'readings';

axios.interceptors.request.use(config => {
    const token = localStorage.getItem('token');

    config.headers = {
        'content-type': 'application/json',
        'x-accesstoken': (token) ? token : ''
    }

    return config;
})

axios.interceptors.response.use(resp => resp, err => {
    console.log(err, 'errrr');

    if(err.response.status === 401 && window.localStorage.getItem('token')) {
        window.localStorage.clear();
        window.location.reload();
        return err;
    }

    return Promise.reject(err.response);
});


export const isAuth = 'pending';
export const isAdminAuth = 'pending';
export const token = localStorage.getItem('token');

// AUTH

export const login = (data) => {
    return axios.post(usersAPI + "/login", data);
}
export const createUser = (payload) => axios.post(usersAPI + "/register", payload);
export const getUser = () => axios.get(usersAPI);
export const updateUser = (payload) => axios.put(usersAPI, payload);
export const changePassword = (payload) => axios.put(usersAPI + '/password', payload);
export const confirmPassword = (payload) => axios.post(usersAPI + '/confirm', payload);
export const loginGoogle = (payload) => axios.post(usersAPI + '/google/auth', payload);
export const removeProfile = () => axios.delete(usersAPI);
export const forgetPass = (payload) => axios.post(usersAPI + '/forgot', payload);
export const checkRecoverCode = (payload) => axios.post(usersAPI + '/check/recover', payload);
export const setNewPass = (payload) => axios.post(usersAPI + '/recover', payload);
export const acceptPrivacy = () => axios.post(usersAPI + '/google/continue', {});
export const declinePrivacy = () => axios.delete(usersAPI + '/google/continue');



export const logout = () => {
    localStorage.removeItem('token');
    window.location.reload();
}

// READINGS
export const getAllReadings = () => axios.get(readingsAPI);
export const createReading = (payload) => axios.post(readingsAPI , payload);
export const updateReadingReq = (id, payload) => axios.put(readingsAPI + '/' + id, payload);
export const deleteReadingReq = (id) => axios.delete(readingsAPI + '/' + id);



// axios.interceptors.response.use(undefined, err => {
//     console.log(err, 'errrr');

//     if(err.response.status === 401 && window.localStorage.getItem('token')) {
//         window.localStorage.clear();
//         window.location.reload();
//         throw err;
//     }

//     return err;
// });