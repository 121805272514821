import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonBase from "@mui/material/ButtonBase";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from '@mui/material/CircularProgress';


// api
import {login, getUser, createReading, getAllReadings, loginGoogle} from '../../../service/requests';

// actions
import {openPopup, closePopup} from "../../../actions/popup";
import {currentUser} from "../../../actions/currentUser";
import {setReadings} from "../../../actions/data";



// icons
import IconButton from "@mui/material/IconButton";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import { Button } from "@mui/material";
import { openNotification } from "../../../actions/notification";
import GoogleAuth from "../../googleAuth/googleAuth";


const mapDispatchToProps = (dispatch) => ({
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  },
  closePopup: () => {
    return dispatch(closePopup());
 },
 currentUser: (payload) => {
    return dispatch(currentUser(payload));
 },
 openNotification: (payload) => {
  return dispatch(openNotification(payload));
},
setReadings: (payload) => {
  return dispatch(setReadings(payload))
},
});

const Login = (props) => {

  const {openPopup, closePopup, currentUser, openNotification} = props;
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isPrivacy, setIsPrivacy] = useState(false);

  const [isLoginBtnDisabled, setIsLoginBtnDisabled] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const onUserChange = (e) => {
    setErrorMessage(null);
    setIsLoginBtnDisabled(false);
    setUser({
      ...user,
      [e.target.id]: e.target.value,
    });
  };


  const loadData = async (userData) => {
    let error = false;
    userData.forEach(async(x) => {
      try {
        const res = await createReading(x);
        console.log('res', res);
      } catch(e) {
        openNotification({text: 'Something went wrong', notificationType: 'error' });
        console.log(e);
        error = true
        return
      }

      if(!error) {
        localStorage.removeItem('userData');
      }
    })


    try {
    const res = await getAllReadings();
    if(res.data.ok) {
      let readings = res.data.readings;
      setReadings({readings: readings});
    }
  } catch(e) {
    openNotification({text: 'Something went wrong', notificationType: 'error' });
    console.log(e)
  }
  }


  const onLogin = async (event) => {
      
      setErrorMessage('');
      event.preventDefault();
      setIsLoading(true);
      setIsLoginBtnDisabled(true);

      window.gtag('event', 'login', {
        eventLabel: 'login'
      });

      try {
          let res = await login(user);
          console.log(res);
          localStorage.setItem("token", res.data.token);
          
          // get user
          try {
            const res = await getUser();
            currentUser({user: res.data.user});
            const userData = localStorage.getItem("userData");

              // upoload data from local storage is exaisted
            if(userData) {
              let updatedUserDate = JSON.parse([userData]);
              loadData(updatedUserDate);
            }

          } catch (error) {
            console.log(error);
            openNotification({text: 'Something went wrong', notificationType: 'error' });
            setIsLoginBtnDisabled(false);
          }
          
          closePopup();

      } catch (error) {
          console.log(error);
          if(error?.data) {
              setErrorMessage('Credenziali errati');
              openNotification({text: 'Credenziali errati', notificationType: 'error' });
          }
              setIsLoading(false);
      }
};


  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };



  return (
    <div className="p-8 sm:py-10 sm:px-14">
      <div className="text-2xl sm:text-4xl font-bold text-blue text-center">Sei già iscritto?</div>
      <div className="text-center mt-2 text-md sm:text-lg">Inserisci le tue credenziali</div>

      <form onSubmit={onLogin}>
        <div className="flex flex-col sm:w-96 w-full mt-5 gap-3 relative">
          <TextField required id="email" type="email" label="Indirizzo email" variant="standard" onChange={(e) => onUserChange(e)} />
          <TextField
            required
            id="password"
            label="Password"
            variant="standard"
            type={showPassword ? "text" : "password"}
            onChange={(e) => onUserChange(e)}
            InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => handleMouseDownPassword(e)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOffRoundedIcon /> : <VisibilityRoundedIcon />}
                  </IconButton>
                ),
              }}
          />
        {errorMessage && <div className="absolute top-0 right-0 text-xs text-red">{errorMessage}</div>}
        </div>

        <div className="text-sm text-blue dark:text-whitish text-left mt-4">
          <span
            className="font-semibold text-purple cursor-pointer transition hover:text-blue hover:underline"
            onClick={() => openPopup({currentPopup: "Forgot"})}
            >Hai dimenticato la password?</span>
        </div>


        {/* <div className="mt-3">
          <FormControlLabel control={<Checkbox />} label="Ricordami" />
        </div> */}
      
        <div className={errorMessage ? 'auth__login--error' : ''}>
          <Button 
            disabled={isLoginBtnDisabled}
            type="submit" 
            className= {isLoginBtnDisabled 
              ? "w-full capitalize mt-7 bg-gray text-white text-lg transition rounded-lg text-center py-2 px-10 pointer-events-none"
              : "w-full capitalize mt-7 bg-blue text-white text-lg cursor-pointer hover:bg-lightBlue transition rounded-lg text-center py-2 px-10"
            }>
            Entra
          </Button>
        </div>

      </form>

      <div className="text-gray mt-2 text-center">O</div>

      <div className="mt-3">
        <GoogleAuth/>
      </div>

      <div className="text-sm text-blue dark:text-whitish text-center mt-8">
        Non ti sei mai registrato?
        <span
          className="font-semibold text-purple ml-2 cursor-pointer transition hover:text-blue hover:underline"
          onClick={() => openPopup({currentPopup: "Signup"})}
        >
          Iscriviti
        </span>
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(Login);
